import Vue from "vue";
import { VAutocomplete, VSelect, VTextarea, VTextField } from "vuetify/lib";

export const SportangoTextField = Vue.component("SportangoTextField", {
  extends: VTextField,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    persistentPlaceholder: {
      type: Boolean,
      default: true
    }
  }
});

export const SportangoSelect = Vue.component("SportangoSelect", {
  extends: VSelect,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    "menu-props": {
      type: Object,
      default: () => {
        return {
          closeOnClick: true,
          closeOnContentClick: true,
          disableKeys: true,
          openOnClick: false,
          maxHeight: 304
        };
      }
    },
    persistentPlaceholder: {
      type: Boolean,
      default: true
    }
  }
});

export const SportangoTextArea = Vue.component("SportangoTextArea", {
  extends: VTextarea,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    persistentPlaceholder: {
      type: Boolean,
      default: true
    }
  }
});

export const SportangoAutocomplete = Vue.component("SportangoAutocomplete", {
  extends: VAutocomplete,
  props: {
    outlined: {
      type: Boolean,
      default: true
    },
    dense: {
      type: Boolean,
      default: true
    },
    persistentPlaceholder: {
      type: Boolean,
      default: true
    }
  }
});
