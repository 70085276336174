



























































import { Storage } from "@/firebase";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { BaseUser } from "@sportango/backend";
import { MISSING_USER_IMAGE } from "@/utils/constants";
import AddPictureIcon from "@/assets/icons/saxcons/gallery-add-linear.svg";
import { LoadingMixin } from "@/mixins/Helpers";

@Component({
  name: "picture-upload-button",
  components: {
    AddPictureIcon
  }
})
export default class PictureUploadButton extends mixins(
  ResponsiveMixin,
  LoadingMixin
) {
  @Prop({ required: true })
  user!: BaseUser | null;
  @Prop({ required: true, default: "" })
  value!: string;
  @Prop({ required: false })
  size!: number | string;

  readonly MISSING_USER_IMAGE = MISSING_USER_IMAGE;

  userUploadedPicture: File | null = null;
  tempBase64Path: string | null = null;
  parentWidth: number | null = null;
  isLoading = true;
  gotError = false;

  transferClickToInput() {
    const input = document.querySelector(`#${this.user?.uid}-picture-input`);
    if (input) {
      (input as HTMLInputElement).click();
    } else {
      throw new Error(
        `File Input button not available for User ${this.user?.uid}`
      );
    }
  }

  get photoURL(): string {
    return this.value;
  }
  set photoURL(val: string) {
    this.$emit("input", val);
  }

  get maxWidth(): number | string {
    if (this.isDesktop) {
      if (this.size) {
        return this.size;
      }
      if (this.parentWidth) {
        return this.parentWidth;
      }
      return "100%";
    } else {
      return 128;
    }
  }

  @Watch("userUploadedPicture")
  async changePicture() {
    this.isLoading = true;
    if (this.userUploadedPicture) {
      const fileRef = ref(Storage, this.user?.uid);
      await uploadBytes(fileRef, this.userUploadedPicture);
      const reader = new FileReader();
      reader.onloadend = (ev) => {
        if (
          ev.target &&
          ev.target.result &&
          typeof ev.target.result === "string"
        ) {
          this.tempBase64Path = ev.target.result;
        }
      };
      reader.readAsDataURL(this.userUploadedPicture);
      this.photoURL = await getDownloadURL(fileRef);
    }
    this.gotError = false;
  }

  mounted() {
    this.parentWidth = (this.$el.parentElement?.clientWidth || 0) - 32;
  }
}
