import { DB } from "@/firebase";
import { forFirestore, fromFirestore } from "@/utils/parser";
import { getFinalArray } from "@/utils/validation";
import { Transaction, TRANSACTIONS_TABLE_NAME } from "@sportango/backend";
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { chunk } from "lodash";
import { ActionTree } from "vuex";
import { RootState } from "../types";

export const transactionsActions: ActionTree<RootState, RootState> = {
  async getTransaction({ commit, getters }, parentItem: string) {
    const q = query(
      collection(DB, TRANSACTIONS_TABLE_NAME),
      where("parentItem", "==", parentItem)
    );
    const { docs } = await getDocs(q);
    commit(
      "transactions",
      getFinalArray<Transaction>(
        getters.transactions,
        docs.map((d) => fromFirestore<Transaction>(d, "id")),
        "id"
      )
    );
  },

  async createTransaction({ commit, getters }, transaction: Transaction) {
    transaction.customerIds = (transaction.customers || []).map(
      (c) => c.uid || ""
    );
    const { id } = await addDoc(
      collection(DB, TRANSACTIONS_TABLE_NAME),
      forFirestore(transaction)
    );
    commit(
      "transactions",
      getFinalArray<Transaction>(
        getters.transactions,
        [
          {
            ...transaction,
            id
          }
        ],
        "id"
      )
    );
  },

  async updateTransaction(
    { commit, getters },
    transaction: Partial<Transaction>
  ) {
    transaction.customerIds = (transaction.customers || []).map(
      (c) => c.uid || ""
    );
    await updateDoc(
      doc(collection(DB, TRANSACTIONS_TABLE_NAME), transaction.id),
      forFirestore(transaction)
    );
    commit(
      "transactions",
      getters.transactions.map((t) => {
        if (t.id === transaction.id) {
          return {
            ...t,
            ...transaction
          };
        }
        return t;
      })
    );
  },

  async getTransactionsForUser({ commit, dispatch, getters }, userId: string) {
    const userIds = [userId];
    getters.profiles.forEach((p) => {
      if (
        (p.uid.includes(userId) || userId.includes(p.uid)) &&
        p.uid !== userId
      ) {
        userIds.push(p.uid);
      }
    });
    const q = query(
      collection(DB, TRANSACTIONS_TABLE_NAME),
      where("customerIds", "array-contains-any", userIds)
    );
    const transactions = (await getDocs(q)).docs.map((d) => {
      const transaction: Transaction = {
        id: d.id,
        ...d.data()
      };
      return transaction;
    });
    await dispatch(
      "getProgramById",
      transactions.map((t) => t.parentItem)
    );
    commit("transactions", transactions);
  },

  async getTransactions(
    { commit, dispatch },
    type: Transaction.TransactionTypeEnum
  ) {
    const transactions = (
      await getDocs(
        query(
          collection(DB, TRANSACTIONS_TABLE_NAME),
          where("transactionType", "==", type)
        )
      )
    ).docs.map((d) => {
      const transaction: Transaction = {
        id: d.id,
        ...d.data()
      };
      return transaction;
    });
    await dispatch(
      "getProgramById",
      transactions.map((t) => t.parentItem)
    );
    commit("transactions", transactions);
  },

  async getTransactionsForParentItems(
    { commit, getters, dispatch },
    transactionIds: string | Array<string>
  ) {
    if (!Array.isArray(transactionIds)) {
      transactionIds = [transactionIds];
    }
    transactionIds = Array.from(new Set(transactionIds));
    if (transactionIds.length > 0) {
      if (transactionIds.length > 5) {
        await Promise.allSettled(
          chunk(transactionIds, 5).map((u) =>
            dispatch("getTransactionsForParentItems", u)
          )
        );
      } else {
        const q = query(
          collection(DB, TRANSACTIONS_TABLE_NAME),
          where("parentItem", "in", transactionIds)
        );
        const { docs } = await getDocs(q);
        commit(
          "transactions",
          getFinalArray<Transaction>(
            getters.transactions,
            docs.map((d) => {
              const transaction: Transaction = {
                id: d.id,
                ...d.data()
              };
              return transaction;
            }),
            "id"
          )
        );
      }
    }
  }
};
