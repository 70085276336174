
































import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import PictureMissingIcon from "@/assets/icons/saxcons/gallery-remove-bold.svg";
import { LoadingMixin } from "@/mixins/Helpers";

@Component({
  name: "user-avatar",
  components: {
    PictureMissingIcon
  }
})
export default class UserAvatar extends mixins(LoadingMixin) {
  @Prop({ required: false, type: [String, Boolean], default: false })
  photoURL!: string | false;
  @Prop({ required: false, type: Number, default: 32 })
  size!: number;
  isLoading = true;
}
