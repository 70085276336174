






















import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Calendar from "@/assets/icons/navigation/calendar.svg";
import CalendarBold from "@/assets/icons/navigation/calendar-bold.svg";
import Programs from "@/assets/icons/navigation/programs.svg";
import ProgramsBold from "@/assets/icons/navigation/programs-bold.svg";
import Courts from "@/assets/icons/navigation/court.svg";
import CourtsBold from "@/assets/icons/navigation/court-bold.svg";
import Coaches from "@/assets/icons/navigation/coach.svg";
import CoachesBold from "@/assets/icons/navigation/coach-bold.svg";
import Reports from "@/assets/icons/saxcons/chart-broken.svg";
import ReportsBold from "@/assets/icons/saxcons/chart-bold.svg";
import Users from "@/assets/icons/saxcons/people-broken.svg";
import UsersBold from "@/assets/icons/saxcons/people-bold.svg";
import Players from "@/assets/icons/saxcons/people-linear.svg";
import PlayersBold from "@/assets/icons/saxcons/people-bold.svg";
import Settings from "@/assets/icons/saxcons/settings-2-linear.svg";
import SettingsBold from "@/assets/icons/saxcons/settings-2-bold.svg";
import Payments from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import PaymentsBold from "@/assets/icons/saxcons/dollar-circle-bold.svg";
import Broadcast from "@/assets/icons/saxcons/megaphone-linear.svg";
import BroadcastBold from "@/assets/icons/saxcons/megaphone-bold.svg";
import Shop from "@/assets/icons/navigation/shop.svg";
import ShopBold from "@/assets/icons/navigation/shop-bold.svg";

@Component({
  name: "nav-link",
  components: {
    Players,
    PlayersBold,
    Calendar,
    CalendarBold,
    Programs,
    ProgramsBold,
    Courts,
    CourtsBold,
    Coaches,
    CoachesBold,
    Reports,
    ReportsBold,
    Users,
    UsersBold,
    Settings,
    SettingsBold,
    Payments,
    PaymentsBold,
    Broadcast,
    BroadcastBold,
    Shop,
    ShopBold
  }
})
export default class NavLink extends Vue {
  @Prop({ required: true })
  private path: string | undefined;
  @Prop({ required: true })
  private component: string | undefined;
  @Prop({ required: true })
  private title: string | undefined;

  private disPatchClick(): void {
    this.$emit("click");
  }
}
