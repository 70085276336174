import { AutoCompleteItem } from "@/components/Inputs/mixins";
import Countries from "@/components/Inputs/regions";
import { DB } from "@/firebase";
import { convertUserPhoneNumber } from "@/store/actions/users";
import { MISSING_USER_IMAGE } from "@/utils/constants";
import { fromFirestore } from "@/utils/parser";
import { AppUser, BaseUser, Court, USERS_TABLE_NAME } from "@sportango/backend";
import { collection, getDocs, query, where } from "firebase/firestore";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";

@Component
export class LoadingMixin extends Vue {
  public isLoading: boolean;
  constructor() {
    super();
    this.isLoading = false;
  }

  public setLoading(
    isLoading: boolean,
    type: "global" | "local" = "local"
  ): void {
    if (type === "global") {
      this.$store.commit("isLoading", isLoading);
    } else {
      this.isLoading = isLoading;
    }
    this.$emit("setLoading", isLoading);
  }

  @Watch("isLoading")
  private updateLoading() {
    this.$emit("setLoading", this.isLoading);
  }
}

@Component
export class EmitClose extends Vue {
  public close(): void {
    this.$emit("close");
  }
  constructor() {
    super();
  }
}

@Component
export class CurrentUserMixin extends Vue {
  public get currentUser(): AppUser | undefined {
    const user = this.$store.getters.currentUser;
    let photoURL = user?.photoURL || null;
    let phoneNumber = user?.phoneNumber || null;
    const parents = user?.additionalInfo?.parentNames || [];
    if (user && !photoURL) {
      if (user.displayName) {
        photoURL = `https://ui-avatars.com/api/?size=256&name=${user.displayName.replace(
          " ",
          "+"
        )}`;
      } else {
        photoURL = MISSING_USER_IMAGE;
      }
    }
    if (user && user.phoneNumber && user.phoneNumber.startsWith("+")) {
      phoneNumber = convertUserPhoneNumber(user.phoneNumber, "app");
    }
    if (user) {
      return {
        ...user,
        photoURL,
        phoneNumber,
        additionalInfo: {
          ...user.additionalInfo,
          parentNames: parents
        }
      };
    }
    return undefined;
  }

  public get profiles(): Array<BaseUser> {
    return this.$store.getters.profiles;
  }

  public get hasProfiles(): boolean {
    return (
      this.$store.getters.profiles.filter(
        (p) => p.uid !== this.$store.getters.currentUser?.uid
      ).length !== 0
    );
  }

  public get availableProfiles(): Array<BaseUser> {
    return this.profiles.filter((p) => p.uid !== this.currentUser?.uid);
  }

  public get isCurrentUserOnlyCoach(): boolean {
    if (!this.currentUser?.permissions.hasCoachAccess) {
      return false;
    } else {
      if (
        this.currentUser.permissions.hasAdminAccess ||
        this.currentUser.permissions.hasPlayerAccess
      ) {
        return false;
      }
      return true;
    }
  }

  public get isCurrentUserOnlyPlayer(): boolean {
    if (this.currentUser?.permissions.hasAdminAccess) {
      return false;
    }
    if (this.currentUser?.permissions.hasCoachAccess) {
      return false;
    }
    return true;
  }

  public get currentUserProfile(): BaseUser | undefined {
    return this.$store.getters.users.find(
      (u) => u.uid === this.currentUser?.uid
    );
  }

  @Watch("currentUser")
  private async fetchUserProfile(): Promise<void> {
    if (this.currentUser?.uid) {
      await this.$store.dispatch("getUsersById", this.currentUser.uid);
    }
  }
}

@Component
export class CourtsMixin extends Vue {
  public get courts(): Array<Court> {
    return this.$store.getters.courts;
  }

  public get courtOptions(): Array<AutoCompleteItem> {
    return this.courts.map((c) => {
      return {
        value: c.id,
        text: c.name
      };
    });
  }

  public get privateLessonCourtOptions(): Array<AutoCompleteItem> {
    return [
      ...this.courtOptions,
      {
        value: "null",
        text: "Virtual"
      }
    ];
  }

  public get courtsAllowed(): boolean {
    return this.$store.getters.featureFlags.courts;
  }
}

@Component
export class AllUsersMixin extends Vue {
  public get allUsers(): Array<BaseUser> {
    return this.$store.getters.users;
  }
}

@Component
export class SubRouteMixin extends Vue {
  @Prop({ required: false, default: false })
  public isSubRoute!: boolean;

  public emitSubRoute(route: string): void {
    this.$emit("subRoute", route);
  }
}

@Component
export class PreventBubblingMixin extends Vue {
  public preventBubbling(event: Event): void {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
  }
}

@Component
export class CaptureEvent extends PreventBubblingMixin {
  event: (Event & { target: HTMLElement }) | null = null;
  public isEventCaptured = false;

  @Prop({ required: false, default: null })
  to!: string;

  captureEvent(e: Event & { target: HTMLElement }) {
    this.preventBubbling(e);
    this.event = e;
    this.isEventCaptured = true;
    if (this.to) {
      this.$router.push(this.to);
    }
  }
  captureMobileEvent(e: Event & { target: HTMLElement }) {
    if (!this.shouldCaptureDesktop || this.to !== null) {
      this.captureEvent(e);
    }
  }
  private get shouldCaptureDesktop(): boolean {
    return !(this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm);
  }
}

@Component
export class DetectPhoneNumber extends Vue {
  public phoneNumberErrorMessage: string | null = null;
  public checkingPhoneNumber = true;

  public async checkIfPhoneNumberUsed(phoneNumber: string, userId?: string) {
    this.checkingPhoneNumber = true;
    const usersWithCurrentPhoneNumber = (
      await getDocs(
        query(
          collection(DB, USERS_TABLE_NAME),
          where("phoneNumber", "==", convertUserPhoneNumber(phoneNumber, "db"))
        )
      )
    ).docs
      .map((d) => fromFirestore<BaseUser>(d, "uid"))
      .filter((u) => !u.isVirtual);
    if (usersWithCurrentPhoneNumber.length === 0) {
      this.phoneNumberErrorMessage = null;
    } else {
      if (
        usersWithCurrentPhoneNumber.length === 1 &&
        usersWithCurrentPhoneNumber[0].uid === userId
      ) {
        this.phoneNumberErrorMessage = null;
      } else {
        this.phoneNumberErrorMessage =
          "This phone number is already used. Please use a different phone number";
      }
    }
    this.checkingPhoneNumber = false;
  }
}

@Component
export class CountryInput extends Vue {
  public get selectedCountry(): string | undefined {
    return undefined;
  }
  get countryOptions(): Array<AutoCompleteItem> {
    return Countries.map((c) => {
      return {
        text: c.countryName,
        value: c.countryShortCode
      };
    });
  }

  get regionOption(): Array<AutoCompleteItem> {
    const country = Countries.find(
      (c) => c.countryShortCode === this.selectedCountry
    );
    if (country) {
      return country.regions.map((r) => {
        return {
          text: r.name,
          value: r.shortCode || r.name
        };
      });
    }
    return [];
  }
}

@Component
export class DelayMixin extends Vue {
  public delay(milliseconds: number) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(milliseconds);
      }, milliseconds);
    });
  }
}
