import "@/plugins/launchDarkly";
import "@/plugins/sentry";
import "@/registerServiceWorker";
import "animate.css";
import Clipboard from "v-clipboard";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { VueMaskDirective } from "v-mask";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/index";

Vue.config.productionTip = false;
Vue.directive("mask", VueMaskDirective);
Vue.use(Clipboard);

Vue.filter("amPm", function (value?: string | null): string {
  if (typeof value !== "string") {
    return "--:-- --";
  }
  const [hour, minute] = value.split(":").map((t) => Number(t));
  if (hour > 12) {
    return `${hour - 12}:${minute === 0 ? "00" : minute} PM`;
  } else if (hour === 12) {
    return "12:00 PM";
  } else {
    return value + " AM";
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App)
}).$mount("#app");
