var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('app-dialog',{attrs:{"value":_vm.showDialog,"width":800},on:{"close":_vm.close}},[_c('v-card',{attrs:{"flat":"","loading":_vm.isLoading}},[_c('v-card-title',[_vm._v(" Contacts ")]),_c('v-data-table',{attrs:{"headers":_vm.alternativeContactHeaders,"items":_vm.AlternativeContacts,"fixed-header":"","mobile-breakpoint":"0","item-key":"itemId","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.phoneNumber",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.phoneNumber || "-")+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.isDesktop)?_c('div',{staticClass:"d-flex justify-end",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editContact', item, index)}}},[_c('edit-icon',{staticClass:"small",attrs:{"stroke":"var(--v-warning-base)"}})],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteContact', index)}}},[_c('delete-icon',{staticClass:"small",attrs:{"stroke":"var(--v-error-base)"}})],1)],1):_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"dark":"","icon":""}},'v-btn',attrs,false),on),[_c('MenuIcon')],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('editContact', item, index)}}},[_c('edit-icon',{staticClass:"small",attrs:{"stroke":"var(--v-warning-base)"}})],1)],1),_c('v-list-item',[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('deleteContact', index)}}},[_c('delete-icon',{staticClass:"small",attrs:{"stroke":"var(--v-error-base)"}})],1)],1)],1)],1)]}}])}),_c('v-card-actions',{staticClass:"justify-end mt-2"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$emit('addContact')}}},[_vm._v("Add Contacts")])],1)],1),_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }