




































import { SportangoTextField } from "@/components/Inputs/overrides";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import PlusIcon from "@/assets/icons/saxcons/add-linear.svg";

@Component({
  name: "multi-input",
  components: {
    SportangoTextField,
    PlusIcon
  }
})
export default class MultiInput extends Vue {
  @VModel({ required: true })
  values!: Array<string>;

  @Prop({ required: false, default: "" })
  label!: string;

  @Prop({ required: false, default: "" })
  placeholder!: string;

  constructor() {
    super();
  }

  addNew() {
    this.values = [...this.values, ""];
  }

  removeItem(idx: number) {
    this.values = [...this.values.filter((val, i) => i !== idx)];
  }

  @Watch("value")
  addInitialValue() {
    if (this.values && this.values.length === 0) {
      this.values.push("");
    }
  }
}
