import Vue from "vue";
import Vuex, { Store, StoreOptions } from "vuex";
import { actions, ActionsDispatch } from "./actions";
import getters from "./getters";
import mutations from "./mutations";
import { RootState } from "./types";

Vue.use(Vuex);
const store: StoreOptions<RootState> = {
  state: {
    isLoading: true,
    currentUser: undefined,
    users: [],
    profiles: [],
    programs: [],
    allProgramsFetched: false,
    events: [],
    privateLessons: [],
    courts: [
      { id: "1", name: "Court 1" },
      { id: "2", name: "Court 2" },
      { id: "3", name: "Court 3" },
      { id: "4", name: "Court 4" },
      { id: "5", name: "Gym" }
    ],
    featureFlags: {
      programs: false,
      "users-admin": false,
      "direct-registration": false,
      "coaches-directory-for-coaches": false,
      courts: false,
      "coach-players": false,
      "coach-merchants": false,
      "cash-payments": false,
      payments: false,
      "terms-and-conditions": false,
      "secondary-profiles": false,
      reports: false,
      "invite-list": false,
      "invite-action": false,
      memberships: false,
      "delete-program": false,
      "joining-fee": false
    },
    invites: [],
    coachPlayers: [],
    merchantInfo: undefined,
    stripeClientLoaded: false,
    transactions: [],
    infoWindow: {
      open: false,
      actionText: "Ok,got it",
      bodyContent: undefined,
      headingContent: undefined
    },
    memberships: [],
    subscriptions: [],
    programMakeups: {}
  },
  mutations: mutations,
  getters: getters,
  actions: actions
};
export default new Store<RootState, ActionsDispatch>(store);
