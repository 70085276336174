import { lightTheme } from "@sportango/site-configuration/out/src/assets/theme";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: lightTheme
    }
  },
  icons: {
    iconfont: "mdi"
  }
});
