






















































import Component, { mixins } from "vue-class-component";
import { Prop, Ref, VModel, Watch } from "vue-property-decorator";
import { VDialog } from "@/types/Dialog";
import dayjs from "dayjs";
import { TextFieldMixin } from "./mixins";
import CalendarIcon from "@/assets/icons/saxcons/calendar-2-linear.svg";

@Component({
  name: "dialog-date-picker",
  components: {
    CalendarIcon
  }
})
export default class DialogDatePicker extends mixins(TextFieldMixin) {
  private pickerActive: boolean;
  @Ref("inputDialog")
  private inputDialog!: VDialog;
  @VModel({ type: [String, Date] })
  field!: string | Date;
  @Prop({ required: true })
  name!: string;
  @Prop({ required: false })
  min!: string | Date | undefined;
  @Prop({ required: false })
  max!: string | Date | undefined;

  private get model(): string {
    if (this.field instanceof Date) {
      return dayjs(this.field).format("YYYY-MM-DD");
    }
    return this.field;
  }

  private set model(model: string) {
    this.field = dayjs(model, "YYYY-MM-DD").toDate();
  }

  get btnText(): string {
    if (this.model) {
      return dayjs(this.model, "YYYY-MM-DD").format("dddd, MMMM DD");
    } else {
      return this.placeholder;
    }
  }

  get minDate(): string | undefined {
    if (this.min instanceof Date) {
      return dayjs(this.min).format("YYYY-MM-DD");
    }
    return this.min;
  }

  get maxDate(): string | undefined {
    if (this.max instanceof Date) {
      return dayjs(this.max).format("YYYY-MM-DD");
    }
    return this.max;
  }

  constructor() {
    super();
    this.pickerActive = false;
  }

  @Watch("pickerActive")
  watchIfActive(): void {
    if (this.pickerActive) {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push(
          `${this.$route.fullPath}&datePickerOpen=${this.name}`
        );
      } else {
        this.$router.push(`${this.$route.path}?datePickerOpen=${this.name}`);
      }
    } else {
      this.$router.back();
    }
  }

  mounted(): void {
    if (this.$route.query.datePickerOpen === this.name) {
      this.$router.replace(
        this.$route.fullPath.replace(`?datePickerOpen=${this.name}`, "")
      );
    }
  }
}
