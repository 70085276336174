import { PLAYER_CATEGORIES } from "@/components/Inputs/selectOptions";
import { WatchLoading } from "@/decorators/Loading";
import { MISSING_USER_IMAGE } from "@/utils/constants";
import { requiredFieldRule } from "@/utils/validation";
import { AdditionUserProperties, BaseUser } from "@sportango/backend";
import dayjs from "dayjs";
import Component, { mixins } from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import {
  CountryInput,
  CurrentUserMixin,
  DetectPhoneNumber,
  LoadingMixin
} from "./Helpers";

@Component
export class ProfileForm extends mixins(
  DetectPhoneNumber,
  LoadingMixin,
  CurrentUserMixin,
  CountryInput
) {
  isValid = false;
  @Prop({ required: true })
  user!: BaseUser | null;
  @Prop({ required: false, default: false })
  addMode!: boolean;

  readonly requiredFieldRule = requiredFieldRule;
  userProfileInfo: AdditionUserProperties = {};
  readonly playerCategories = PLAYER_CATEGORIES;
  phoneNumber = "";
  photoURL: string | null = "";

  get selectedCountry(): string | undefined {
    return this.userProfileInfo.address?.country;
  }

  get shouldShowPlayerProfile(): boolean {
    if (this.user?.permissions.hasPlayerAccess) {
      return true;
    }
    return false;
  }

  get shouldShowCoachProfile(): boolean {
    if (this.user?.permissions.hasCoachAccess) {
      return true;
    }
    return false;
  }
  get privateLessonMessage(): string {
    if (this.user?.uid === this.currentUser?.uid) {
      return "Do you do private lessons?";
    }
    return "Does this coach do private lessons?";
  }
  get age(): number {
    const current = dayjs();
    const dOb = dayjs(this.userProfileInfo.dateOfBirth);
    return current.diff(dOb, "y");
  }

  get playerCategory(): string {
    if (this.age < 18) {
      return "Junior";
    } else {
      return "Adult 18+";
    }
  }
  async mounted() {
    await this.watchUser();
  }
  @Watch("user")
  async watchUser() {
    if (this.user?.additionalInfo) {
      this.userProfileInfo = this.user.additionalInfo;
      if (!this.userProfileInfo.address) {
        this.userProfileInfo.address = {
          country: "US"
        };
      }
      if (!this.userProfileInfo.coachPricing) {
        this.userProfileInfo.coachPricing = {};
      }
    }
    if (this.addMode) {
      this.photoURL = this.user?.photoURL || null;
      // Don't do anything
    } else if (this.user) {
      if (!this.$store.getters.users.find((u) => u.uid === this.user?.uid)) {
        await this.$store.dispatch("getUsersById", this.user.uid);
      }
      const userInfo = this.$store.getters.users.find(
        (u) => u.uid === this.user?.uid
      );
      if (userInfo && userInfo.phoneNumber) {
        this.phoneNumber = userInfo.phoneNumber;
      }
      if (userInfo && userInfo.photoURL) {
        this.photoURL = userInfo.photoURL;
      }
    }
  }

  @WatchLoading()
  async save() {
    const payload: { uid?: string; newData: Partial<BaseUser> } = {
      uid: this.user?.uid,
      newData: {
        additionalInfo: this.userProfileInfo,
        displayName: [
          this.userProfileInfo.firstName,
          this.userProfileInfo.lastName
        ].join(" "),
        photoURL: this.photoURL || undefined
      }
    };
    if (payload.newData.photoURL === MISSING_USER_IMAGE) {
      payload.newData.photoURL = undefined;
    }
    if (this.phoneNumber) {
      payload.newData.phoneNumber = this.phoneNumber;
    }
    if (this.addMode) {
      await this.$store.dispatch("addUserProfile", payload);
      this.$emit("saved");
    } else {
      await this.$store.dispatch("updateUser", payload);
    }
  }

  @Watch("phoneNumber")
  async watchPhoneNumber() {
    await this.checkIfPhoneNumberUsed(this.phoneNumber, this.user?.uid);
  }
}
