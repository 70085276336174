























import { BaseUser } from "@sportango/backend";
import { getFinalArray } from "@/utils/validation";
import { LoadingMixin } from "@/mixins/Helpers";
import { findUsers, USER_TYPES } from "@/store/actions/users";
import Component, { mixins } from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import {
  AutoCompleteItem,
  AutoCompleteMixin,
  TextFieldMixin
} from "../Inputs/mixins";
import MobileAutoComplete from "./MobileAutoComplete.vue";

@Component({
  name: "users-auto-complete",
  components: {
    MobileAutoComplete
  }
})
export default class UsersAutoComplete extends mixins(
  TextFieldMixin,
  AutoCompleteMixin,
  LoadingMixin
) {
  @VModel({ type: [String, Array] })
  field!: string | Array<string>;

  @Prop({ required: false, default: 5, type: Number })
  maxResults!: number;
  @Prop({ required: false, default: () => ["PLAYER", "COACH", "ADMIN"] })
  userTypes!: Array<USER_TYPES>;
  @Prop({ required: false })
  ignoreIds!: Array<string> | undefined;
  @Prop({ required: false })
  idsToConsider!: Array<string> | undefined;
  @Prop({ required: false, default: false })
  allowDisabled!: boolean;

  private availableUsers: Array<AutoCompleteItem> = [];

  get usersToShow(): Array<AutoCompleteItem> {
    let usersToShow = this.availableUsers;
    if (this.ignoreIds) {
      usersToShow = usersToShow.filter((u) => {
        if (this.disabled) {
          if (Array.isArray(this.field)) {
            return this.field.includes(u.value.toString());
          } else if (typeof this.field === "string") {
            return u.value === this.field;
          }
        }
        return !this.ignoreIds?.includes(u.value.toString());
      });
    }
    return usersToShow;
  }

  @Watch("idsToConsider")
  private async fetchUsers(searchText: string): Promise<void> {
    const userTypes = this.userTypes;
    if (this.idsToConsider) {
      await this.$store.dispatch("getUsersById", this.idsToConsider);
      this.availableUsers = [
        ...this.$store.getters.users
          .filter((u) => this.idsToConsider?.includes(u.uid))
          .map((u) => {
            const autoCompleteItem: AutoCompleteItem = {
              value: u.uid,
              text: u.displayName || u.email || ""
            };
            return autoCompleteItem;
          })
      ];
    } else {
      this.availableUsers = (
        await Promise.all(
          userTypes.map(async (u) => {
            const users = await findUsers(
              searchText,
              u,
              this.maxResults + (this.ignoreIds?.length || 0),
              !this.allowDisabled
            );
            this.$store.commit(
              "users",
              getFinalArray<BaseUser>(this.$store.getters.users, users, "uid")
            );
            return users.map((u) => {
              const res: AutoCompleteItem = {
                text: u.displayName || u.email || "",
                value: u.uid
              };
              return res;
            });
          })
        )
      ).reduce((p: Array<AutoCompleteItem>, n: Array<AutoCompleteItem>) => {
        return [...p, ...n];
      });
      if (this.field) {
        let existingUsers = this.field;
        if (typeof this.field === "string") {
          existingUsers = [this.field];
        }
        await this.$store.dispatch("getUsersById", existingUsers);
        this.availableUsers = [
          ...this.availableUsers,
          ...this.$store.getters.users
            .filter((u) => existingUsers.includes(u.uid))
            .map((u) => {
              const autoCompleteValue: AutoCompleteItem = {
                value: u.uid,
                text: u.displayName || ""
              };
              return autoCompleteValue;
            })
        ];
      }
    }
  }

  async mounted(): Promise<void> {
    await this.fetchUsers("");
  }
}
