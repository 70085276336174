var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-list',{staticClass:"mobile-drawer-container",attrs:{"nav":""}},[_vm._l((_vm.remainingMenuItems),function(item){return _c('nav-link',{key:item.path,attrs:{"path":item.path,"component":item.name,"title":item.name}})}),_c('v-divider'),(_vm.currentUser)?_c('v-list-item',{staticClass:"nav-item user-link bg-app mt-auto d-flex align-center justify-start",attrs:{"link":"","to":"/profile/current"}},[_c('v-list-item-icon',[_c('v-avatar',{attrs:{"height":"36","width":"36","min-width":"36","rounded":"18"}},[_c('v-img',{attrs:{"contain":"","height":"36","width":"36","rounded":"","src":_vm.currentUser.photoURL}})],1)],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.currentUser ? _vm.currentUser.displayName || _vm.currentUser.email : "Add Your Name")+" ")])],1):_vm._e(),(
      _vm.isCurrentUserOnlyPlayer &&
      _vm.$store.getters.featureFlags['secondary-profiles']
    )?_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"px-0 nav-item",attrs:{"to":"/add-profile"}},[_c('v-list-item-icon',[_c('user-add-icon',{staticStyle:{"height":"32px","width":"32px"},attrs:{"viewBox":"0 0 24 24"}})],1),_c('v-list-item-title',[_vm._v(" Add Profile ")])],1):_vm._e(),(
      _vm.isCurrentUserOnlyPlayer &&
      _vm.hasProfiles &&
      _vm.$store.getters.featureFlags['secondary-profiles']
    )?_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"px-0 nav-item",attrs:{"tag":"button"},on:{"click":function($event){_vm.dialogOpen = true}}},[_c('v-list-item-icon',[_c('user-switch-icon',{staticStyle:{"height":"32px","width":"32px","transform":"scale(2.5) translateY(-4.5px)"}})],1),_c('v-list-item-title',[_vm._v(" Switch Profile ")])],1):_vm._e(),_c('v-divider'),_c('v-btn',{staticClass:"d-flex justify-start font-weight-bold",attrs:{"color":"error","text":"","block":"","large":""},on:{"click":_vm.logOut}},[_vm._v(" Logout "),_c('logout-icon',{staticClass:"ml-3",staticStyle:{"stroke":"var(--v-error-base)"}})],1),_c('app-dialog',{model:{value:(_vm.dialogOpen),callback:function ($$v) {_vm.dialogOpen=$$v},expression:"dialogOpen"}},[_c('v-card',{attrs:{"min-width":"350","width":"auto"}},[_c('v-card-title',[_vm._v(" Select a profile to change ")]),_c('profile-list',{on:{"switched":function($event){_vm.close();
          _vm.dialogOpen = false;}}})],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }