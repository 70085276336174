import { DB } from "@/firebase";
import { forFirestore, fromFirestore } from "@/utils/parser";
import { getFinalArray } from "@/utils/validation";
import {
  Private,
  PrivateEntity,
  PRIVATE_LESSONS_TABLE_NAME
} from "@sportango/backend";
import {
  collection,
  doc,
  documentId,
  getDocs,
  query,
  updateDoc,
  where
} from "firebase/firestore";
import { chunk } from "lodash";
import { ActionTree } from "vuex";
import { RootState } from "../types";

function getPlayerIds(lessons: Array<Private>): Array<string> {
  const res = [
    ...[lessons.map((l) => l.coach || "").filter((c) => c.length !== 0)],
    ...lessons.map((l) => l.privatePlayers || []).filter((p) => p.length !== 0)
  ];
  if (res.length > 0) {
    return res.reduce((o, n) => {
      let res: Array<string> = [];
      if (o) {
        res = [...res, ...o];
      }
      if (n) {
        res = [...res, ...n];
      }
      return res;
    });
  }
  return [];
}

export const privateLessonActions: ActionTree<RootState, RootState> = {
  async getPrivateLessonsById(
    { commit, getters, dispatch },
    ids: Array<string> | string
  ) {
    if (!Array.isArray(ids)) {
      ids = [ids];
    }
    ids = Array.from(
      new Set(
        ids.filter(
          (id) => getters.privateLessons.find((p) => p.id === id) === undefined
        )
      )
    );
    if (ids.length > 0) {
      if (ids.length > 5) {
        await Promise.allSettled(
          chunk(ids, 5).map((u) => dispatch("getPrivateLessonsById", u))
        );
      } else {
        const q = query(
          collection(DB, PRIVATE_LESSONS_TABLE_NAME),
          where(documentId(), "in", ids)
        );
        const { docs } = await getDocs(q);
        const lessons = docs.map((d) => fromFirestore<Private>(d, "id"));
        commit(
          "privateLessons",
          getFinalArray(getters.privateLessons, lessons, "id")
        );

        await dispatch("getUsersById", getPlayerIds(lessons));
      }
    }
  },

  async updatePrivateLesson(
    { commit, getters, dispatch },
    payload: Partial<Private>
  ) {
    await updateDoc(
      doc(collection(DB, PRIVATE_LESSONS_TABLE_NAME), payload.id),
      forFirestore(payload)
    );
    commit(
      "privateLessons",
      getters.privateLessons.map((p) => {
        if (p.id === payload.id) {
          return new PrivateEntity({
            ...p,
            ...payload
          });
        }
        return new PrivateEntity(p);
      })
    );
    if (payload.privatePlayers) {
      await dispatch("getUsersById", payload.privatePlayers);
    }
  }
};
