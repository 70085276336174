





























import AppDialog from "@/components/Navigation/AppDialog.vue";
import Component from "vue-class-component";
import { Mixins } from "vue-property-decorator";
import { ResponsiveMixin } from "@/mixins/Responsive";
@Component({
  name: "requested-dialog",
  components: {
    AppDialog
  }
})
export default class RequestedDialog extends Mixins(ResponsiveMixin) {
  get infoWindow() {
    return this.$store.state.infoWindow;
  }
}
