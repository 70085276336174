import { DB } from "@/firebase";
import { forFirestore, fromFirestore } from "@/utils/parser";
import {
  Event,
  EventEntity,
  EventPlayerInfo,
  EVENTS_TABLE_NAME,
  EVENT_QUERYABLE_FIELDS
} from "@sportango/backend";
import dayjs, { Dayjs } from "dayjs";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  QueryConstraint,
  Timestamp,
  updateDoc,
  where
} from "firebase/firestore";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { USER_TYPES } from "./users";

export type CalendarType = "week" | "day" | "month";

export interface GetEventsOptions {
  type: CalendarType;
  refDate: string;
}

export interface GetUserEventOptions extends GetEventsOptions {
  userType: USER_TYPES;
  userId: string;
}

export interface UpdateEventTimings {
  id: string;
  start: Date;
  end: Date;
}

function getRangeByCalendarType(options: GetEventsOptions): {
  start: Dayjs;
  end: Dayjs;
} {
  const times = {
    start: dayjs(options.refDate).hour(0).minute(0).second(0),
    end: dayjs(options.refDate).hour(23).minute(59).second(59)
  };
  switch (options.type) {
    case "week":
      times.start = times.start
        .day(0)
        .subtract(new Date().getTimezoneOffset(), "m");
      times.end = times.end
        .day(6)
        .subtract(new Date().getTimezoneOffset(), "m");
      break;
    case "month":
      times.start = times.start
        .startOf("month")
        .subtract(new Date().getTimezoneOffset(), "m");
      times.end = times.end
        .endOf("month")
        .subtract(new Date().getTimezoneOffset(), "m");
      break;
  }
  return times;
}

export function getEventsQuery(
  options: GetEventsOptions
): Array<QueryConstraint> {
  const times = getRangeByCalendarType(options);
  return [
    where(
      EVENT_QUERYABLE_FIELDS.START_TIME,
      ">=",
      Timestamp.fromDate(times.start.toDate())
    ),
    where(
      EVENT_QUERYABLE_FIELDS.START_TIME,
      "<=",
      Timestamp.fromDate(times.end.toDate())
    )
  ];
}

export function getProgramEventsQuery(
  options: GetEventsOptions
): Array<QueryConstraint> {
  return [
    where(EVENT_QUERYABLE_FIELDS.EVENT_TYPE, "==", Event.EventTypeEnum.Program),
    ...getEventsQuery(options)
  ];
}

export function getEventsForUserQuery(
  options: GetUserEventOptions
): Array<QueryConstraint> {
  const constraints: Array<QueryConstraint> = [];
  if (options.userType === "COACH") {
    constraints.push(
      where(EVENT_QUERYABLE_FIELDS.COACHES, "array-contains", options.userId)
    );
  }
  if (options.userType === "PLAYER") {
    constraints.push(
      where(EVENT_QUERYABLE_FIELDS.PLAYERS, "array-contains", options.userId)
    );
  }
  return [...constraints, ...getEventsQuery(options)];
}

export const eventActions: ActionTree<RootState, RootState> = {
  async getEvents({ commit }, options: GetEventsOptions): Promise<void> {
    const q = query(
      collection(DB, EVENTS_TABLE_NAME),
      ...getEventsQuery(options)
    );
    const { docs } = await getDocs(q);
    commit(
      "events",
      docs.map((d) => new EventEntity(fromFirestore<Event>(d)))
    );
  },

  async getProgramEvents({ commit }, options: GetEventsOptions): Promise<void> {
    const q = query(
      collection(DB, EVENTS_TABLE_NAME),
      ...getProgramEventsQuery(options)
    );
    const { docs } = await getDocs(q);
    commit(
      "events",
      docs.map((d) => new EventEntity(fromFirestore<Event>(d, "id")))
    );
  },

  async getEventsForUser(
    { commit },
    options: GetUserEventOptions
  ): Promise<void> {
    const q = query(
      collection(DB, EVENTS_TABLE_NAME),
      ...getEventsForUserQuery(options)
    );
    const { docs } = await getDocs(q);
    commit(
      "events",
      docs.map((d) => new EventEntity(fromFirestore<Event>(d, "id")))
    );
  },

  async updateEventTimings(
    { commit, getters },
    payload: UpdateEventTimings
  ): Promise<void> {
    await updateDoc(
      doc(collection(DB, EVENTS_TABLE_NAME), payload.id),
      forFirestore({
        startTime: payload.start,
        endTime: payload.end
      })
    );
    commit(
      "events",
      getters.events.map((e) => {
        if (e.id === payload.id) {
          return {
            ...e,
            startTime: payload.start,
            endTime: payload.end
          };
        }
        return e;
      })
    );
  },

  async updateEvent(
    { commit, getters },
    newEvent: Partial<Event>
  ): Promise<void> {
    if (newEvent.players) {
      newEvent.playerIds = [];
      newEvent.players.forEach((p) => {
        if (p.uid) {
          newEvent.playerIds?.push(p.uid);
        }
      });
    }
    await updateDoc(
      doc(collection(DB, EVENTS_TABLE_NAME), newEvent.id),
      forFirestore(newEvent)
    );
    commit(
      "events",
      getters.events.map((e) => {
        if (e.id === newEvent.id) {
          return {
            ...e,
            ...newEvent
          };
        }
        return e;
      })
    );
  },

  async deleteEvent({ commit, getters }, id: string) {
    await deleteDoc(doc(collection(DB, EVENTS_TABLE_NAME), id));
    commit(
      "events",
      getters.events.filter((e) => e.id !== id)
    );
  },

  async createEvent(state, newEvent: Event) {
    await addDoc(collection(DB, EVENTS_TABLE_NAME), forFirestore(newEvent));
  }
};

export interface CalendarEvent {
  name?: string;
  start?: Date | number;
  end?: Date | number;
  color?: "info" | "success" | "primary" | "warning" | "accent" | "secondary";
  timed?: boolean;
  eventType?: Event.EventTypeEnum;
  id?: string;
  canDrag?: boolean;
  parentItem?: string;
  confirmed?: boolean;
  cancelled?: boolean;
  coaches?: Array<string>;
  players?: Array<EventPlayerInfo>;
  isRequested?: boolean;
  isMakeup?: boolean;
  requests?: Array<string>;
}

export function parseEventsForCalendar(
  events: Array<Event>
): Array<CalendarEvent> {
  return events.map((e) => {
    const event: CalendarEvent = {
      name: e.name,
      start: e.startTime,
      end: e.endTime,
      timed: e.timed === undefined ? true : e.timed,
      color: e.eventType === "PROGRAM" ? "info" : "success",
      id: e.id,
      eventType: e.eventType,
      parentItem: e.parentItem,
      confirmed: e.confirmed,
      cancelled: e.cancelled,
      coaches: e.coaches,
      players: e.players,
      isRequested: e.isRequest,
      isMakeup: e.isMakeup,
      requests: e.requests
    };
    if (e.cancelled) {
      event.color = "secondary";
    }
    return event;
  });
}
