import { ValidationRules } from "@/utils/validation";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export class TextFieldMixin extends Vue {
  @Prop({ required: false, default: "" })
  label!: string;
  @Prop({ required: false, default: "" })
  placeholder!: string;
  @Prop({ required: false })
  rules!: ValidationRules;
  @Prop({ required: false, default: true })
  dense!: string | boolean;
  @Prop({ required: false })
  rounded!: string | boolean;
  @Prop({ required: false })
  disabled!: string | boolean;
  @Prop({ required: false })
  color!: string | undefined;
  @Prop({ required: false, default: false })
  hideDetails!: string | boolean | undefined;
}

export interface AutoCompleteItem {
  text: string;
  value: string | number;
  disabled?: boolean;
  divider?: boolean;
  header?: string;
}

@Component
export class AutoCompleteMixin extends Vue {
  @Prop({ required: false })
  multiple!: boolean;
  @Prop({ required: false })
  items!: Array<string | AutoCompleteItem>;
  @Prop({ required: true })
  name!: string;
  @Prop({ required: false, default: 0, type: Number })
  minLength!: number;
  @Prop({
    required: false,
    default: "Must select at least 1",
    type: String
  })
  minLengthMessage!: string;
  @Prop({ required: false, default: 100, type: Number })
  maxLength!: number;
  @Prop({
    required: false,
    default: "Cannot select more than 100",
    type: String
  })
  maxLengthMessage!: string;
}
