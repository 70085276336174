import { MISSING_USER_IMAGE } from "@/utils/constants";
import {
  AppUser,
  BaseUser,
  Event,
  Program,
  ProgramMakeupDetails
} from "@sportango/backend";
import { MutationTree } from "vuex";
import { convertUserPhoneNumber } from "./actions/users";
import { IInfoWindow, RootState } from "./types";

const mutations: MutationTree<RootState> = {
  isLoading(state, newValue: boolean) {
    state.isLoading = newValue;
  },
  currentUser(state, user: AppUser) {
    state.currentUser = {
      ...user
    };
  },
  users(state, users: Array<BaseUser>) {
    state.users = [...users.map((u) => mutateUser(u))];
  },

  profiles(state, profiles: Array<BaseUser>) {
    state.profiles = [...profiles.map((u) => mutateUser(u))];
  },
  infoWindow(state, payload: IInfoWindow) {
    state.infoWindow = payload;
  },
  programs(state, programs: Program[]) {
    programs = programs.filter((p) => p !== undefined);
    programs.forEach((p: Program) => {
      if (p.days) {
        p.days = p.days.sort(
          (p1, p2) =>
            Program.options.days.indexOf(p1) - Program.options.days.indexOf(p2)
        );
      }
      if (p.programPlayers) {
        p.programPlayers = p.programPlayers
          .map((pl) => {
            if (pl.days) {
              pl.days = pl.days.sort(
                (pl1, pl2) =>
                  Program.options.days.indexOf(pl1) -
                  Program.options.days.indexOf(pl2)
              );
            }
            return pl;
          })
          .sort((pl1, pl2) => Number(pl1.startDate) - Number(pl2.startDate));
      }
      if (p.playerRequests) {
        p.playerRequests = p.playerRequests
          .map((pr) => {
            if (pr.days) {
              pr.days = pr.days.sort(
                (pr1, pr2) =>
                  Program.options.days.indexOf(pr1) -
                  Program.options.days.indexOf(pr2)
              );
            }
            return pr;
          })
          .sort((pR1, pR2) => Number(pR1.startDate) - Number(pR2.startDate));
      }
    });
    programs = programs.sort((p1, p2) => {
      const dateDiff = Number(p1.startDate) - Number(p2.startDate);
      if (dateDiff === 0) {
        const sportDiff =
          Program.options.sport.indexOf(p1.sport || "") -
          Program.options.sport.indexOf(p2.sport || "");
        if (sportDiff === 0 && p1.name && p2.name) {
          if (p1.name.toLowerCase() < p2.name.toLocaleLowerCase()) {
            return -1;
          }
          if (p2.name.toLowerCase() < p1.name.toLowerCase()) {
            return 1;
          }
          return 0;
        }
        return sportDiff;
      }
      return 0 - dateDiff;
    });
    state.programs = [...programs];
  },
  programMakeups(state, makeupDetails: Record<string, ProgramMakeupDetails>) {
    state.programMakeups = {
      ...state.programMakeups,
      ...makeupDetails
    };
  },
  events(state, events: Array<Event>) {
    state.events = [
      ...events.map((e) => {
        if (e.players) {
          e.playerIds = e.players.map((p) => p.uid || "");
        }
        return e;
      })
    ];
  },
  allProgramsFetched(state, allProgramsFetched) {
    state.allProgramsFetched = allProgramsFetched;
  },
  privateLessons(state, allLessons) {
    state.privateLessons = [...allLessons];
  },
  featureFlags(state, flags) {
    state.featureFlags = flags;
  },
  coachPlayers(state, players: Array<BaseUser>) {
    state.coachPlayers = [...players.map((p) => mutateUser(p))];
  },
  merchantInfo(state, newMerchantInfo) {
    state.merchantInfo = newMerchantInfo;
  },
  stripeClientLoaded(state, stripeClientLoaded) {
    state.stripeClientLoaded = stripeClientLoaded;
  },
  transactions(state, newTransactions) {
    state.transactions = [...newTransactions];
  },
  invites(state, newInvites) {
    state.invites = [...newInvites];
  },
  memberships(states, newMemberships) {
    states.memberships = [...newMemberships];
  },
  subscriptions(state, newSubscriptions) {
    state.subscriptions = [...newSubscriptions];
  }
};

export default mutations;

function mutateUser(u: BaseUser): BaseUser {
  if (!u.displayName) {
    if (u.additionalInfo?.firstName || u.additionalInfo?.lastName) {
      u.displayName =
        `${u.additionalInfo.firstName} ${u.additionalInfo.lastName}`.trim();
    }
  } else {
    const [firstName, lastName] = u.displayName.split(" ");
    u.additionalInfo = {
      ...u.additionalInfo,
      firstName,
      lastName
    };
  }
  if (!u.photoURL || u.photoURL === MISSING_USER_IMAGE) {
    if (u.displayName) {
      u.photoURL = `https://ui-avatars.com/api/?size=256&name=${u.displayName.replace(
        " ",
        "+"
      )}`;
    } else {
      u.photoURL = MISSING_USER_IMAGE;
    }
  }
  if (u.phoneNumber && u.phoneNumber.startsWith("+")) {
    u.phoneNumber = convertUserPhoneNumber(u.phoneNumber, "app");
  }
  if (u.additionalInfo && !u.additionalInfo.parentNames) {
    u.additionalInfo.parentNames = [];
  }
  return u;
}
