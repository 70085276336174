







import UserProfileForm from "@/components/users/UserProfileForm.vue";
import { CurrentUserMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";
import { BaseUser } from "@sportango/backend";
import { convertUser } from "@/firebase";

@Component({
  name: "current-user-profile",
  components: {
    UserProfileForm
  }
})
export default class CurrentUserProfile extends mixins(CurrentUserMixin) {
  get userForForm(): BaseUser | null {
    if (this.currentUser) {
      return convertUser(this.currentUser);
    }
    return null;
  }
}
