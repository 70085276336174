













































































































































































































import { AdditionUserProperties, BaseUser } from "@sportango/backend";
import { requiredFieldRule } from "@/utils/validation";
import {
  CurrentUserMixin,
  DetectPhoneNumber,
  LoadingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import EditIcon from "@/assets/icons/saxcons/edit-broken.svg";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import { PLAYER_CATEGORIES } from "@/components/Inputs/selectOptions";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import { Storage } from "@/firebase";
import EmailIcon from "@/assets/icons/saxcons/sms-linear.svg";
import dayjs from "dayjs";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";
import { SportangoTextField } from "../Inputs/overrides";
import MultiInput from "@/components/Inputs/MultiInput.vue";

@Component({
  name: "home-profile-form",
  components: {
    MultiInput,
    UsersAutoComplete,
    EditIcon,
    DialogDatePicker,
    EmailIcon,
    SportangoTextField
  }
})
export default class HomeProfileForm extends mixins(
  ResponsiveMixin,
  CurrentUserMixin,
  LoadingMixin,
  DetectPhoneNumber
) {
  isValid = false;
  private userProfileInfo: AdditionUserProperties = {};
  readonly playerCategories = PLAYER_CATEGORIES;
  private phoneNumber = "";

  uploadingPicture = false;
  userUploadedPicture: File | null = null;
  requiredFieldRule = requiredFieldRule;

  get isReallyValid(): boolean {
    if (this.isValid) {
      return this.phoneNumberErrorMessage === null;
    }
    return false;
  }

  get age(): number {
    const current = dayjs();
    const dOb = dayjs(this.userProfileInfo.dateOfBirth);
    return current.diff(dOb, "y");
  }

  async mounted() {
    if (this.currentUser?.additionalInfo) {
      this.userProfileInfo = {
        ...this.currentUser.additionalInfo
      };
      if (!this.userProfileInfo.address) {
        this.userProfileInfo.address = {
          country: "US"
        };
      }
      if (!this.userProfileInfo.playerCategory) {
        this.userProfileInfo.playerCategory = "junior";
      }
    }
    this.phoneNumber = this.currentUser?.phoneNumber || "";
  }

  async save() {
    this.isLoading = true;
    const payload: { uid?: string; newData: Partial<BaseUser> } = {
      uid: this.currentUser?.uid,
      newData: {
        additionalInfo: this.userProfileInfo,
        displayName: [
          this.userProfileInfo.firstName,
          this.userProfileInfo.lastName
        ].join(" ")
      }
    };
    if (this.currentUser?.additionalInfo?.coaches) {
      payload.newData.additionalInfo = {
        ...payload.newData.additionalInfo,
        coaches: [...this.currentUser.additionalInfo.coaches]
      };
    }
    if (this.phoneNumber) {
      payload.newData.phoneNumber = this.phoneNumber;
    }
    await this.$store.dispatch("updateUser", payload);
    this.$emit("save", payload.newData);
    this.isLoading = false;
  }

  transferClickToInput() {
    const input = document.querySelector(
      `#profilePicture-${this.currentUser?.uid}`
    ) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  @Watch("userUploadedPicture")
  async changePicture() {
    this.uploadingPicture = true;
    if (this.userUploadedPicture) {
      const fileRef = ref(Storage, this.currentUser?.uid);
      await uploadBytes(fileRef, this.userUploadedPicture);
      const newPhotoUrl = await getDownloadURL(fileRef);
      await this.$store.dispatch("updateUser", {
        uid: this.currentUser?.uid,
        newData: {
          photoURL: newPhotoUrl
        }
      });
      this.$emit("uploaded", newPhotoUrl);
    }
    this.uploadingPicture = false;
  }

  @Watch("phoneNumber")
  async watchPhoneNumber() {
    await this.checkIfPhoneNumberUsed(this.phoneNumber, this.currentUser?.uid);
  }
}
