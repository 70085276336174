







































import Component, { mixins } from "vue-class-component";
import { onAuthStateChanged } from "firebase/auth";
import {
  Auth,
  identifyUserForLaunchDarkly,
  parseAppUser,
  triggerSubscriptionUpdate,
  triggerUpdateIfStripeIdMissing
} from "@/firebase";
import { SW } from "./mixins/SW";
import { Watch } from "vue-property-decorator";
import * as Sentry from "@sentry/vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { FeatureFlags } from "@/store/types";
import { loadStripe } from "@stripe/stripe-js";
import Vue from "vue";
import { BaseStripeInfo } from "./mixins/Stripe";
import { STRIPE_PUBLIC_KEY } from "@sportango/site-configuration/out/src/payments/config";
import RequestedDialog from "@/components/Dialogs/RequestedDialog.vue";
@Component({
  components: {
    LottieAnimation,
    RequestedDialog
  }
})
export default class App extends mixins(SW, BaseStripeInfo) {
  get isLoading(): boolean {
    return this.$store.getters.isLoading;
  }

  async mounted(): Promise<void> {
    onAuthStateChanged(Auth, async (user) => {
      if (user) {
        const newUser = await parseAppUser(user);
        this.$store.commit("currentUser", newUser);
        await this.$store.dispatch("fetchUserProfiles", newUser.email);
        Sentry.setUser({
          email: newUser.email || undefined,
          id: newUser.uid || undefined,
          username: newUser.displayName || undefined
        });
        await identifyUserForLaunchDarkly(this.$ldClient, newUser);
        // Dispatch action to fetch merchant
        await this.$store.dispatch("fetchMerchantInfo");
        if (this.merchantInfo?.merchantId) {
          await triggerUpdateIfStripeIdMissing(
            newUser,
            this.merchantInfo.merchantId
          );
          await triggerSubscriptionUpdate(
            newUser,
            this.merchantInfo.merchantId
          );
        }
        if (
          this.$route.path.includes("auth") ||
          this.$route.path.includes("register")
        ) {
          try {
            await this.$router.push("/");
          } catch (err) {
            console.error(err);
          }
        }

        Vue.prototype.$stripe = await loadStripe(STRIPE_PUBLIC_KEY, {
          stripeAccount: this.merchantInfo?.merchantId || ""
        });
        // instantiate new stripe axios client and set to this.$stripeClient.
        Vue.prototype.$stripeClient = this.createStripClient();
        this.$store.commit("stripeClientLoaded", true);
      } else {
        this.$store.commit("currentUser", undefined);
        if (
          !(
            this.$route.path.includes("auth/login") ||
            this.$route.path.includes("register")
          )
        ) {
          await this.$router.push("/auth/login");
        }
      }
      this.$store.commit("isLoading", false);
    });
    await this.$ldClient.waitUntilReady();
    // Load all flags
    const flags = this.$ldClient.allFlags() as FeatureFlags;
    this.$store.commit("featureFlags", flags);
    // Watch for flags
    this.$ldClient.on("change", async (flags) => {
      const currentFlags = Object.keys(flags) as Array<keyof FeatureFlags>;
      const newFlags: FeatureFlags = {
        ...this.$store.getters.featureFlags
      };
      currentFlags.forEach((flag) => {
        newFlags[flag] = flags[flag].current;
      });
      this.$store.commit("featureFlags", newFlags);
    });
  }
  refreshApp() {
    this.updateExists = false;
    // Make sure we only send a 'skip waiting' message if the SW is waiting
    if (!this.registration || !this.registration.waiting) return;
    // Send message to SW to skip the waiting and activate the new SW
    this.registration.waiting.postMessage({ type: "SKIP_WAITING" });
  }

  @Watch("$route.path")
  updateTitle() {
    document.title = `Sportango | ${this.$route.name}`;
  }
}
