







































































































import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { VModel, Prop, Watch } from "vue-property-decorator";
import closeIcon from "@/assets/icons/saxcons/close-circle-linear.svg";
import {
  TextFieldMixin,
  AutoCompleteMixin,
  AutoCompleteItem
} from "@/components/Inputs/mixins";
import { LoadingMixin } from "@/mixins/Helpers";
import ArrowBack from "@/assets/icons/saxcons/arrow-square-left-linear.svg";
import { ValidationRules } from "@/utils/validation";

@Component({
  name: "mobile-auto-complete",
  components: {
    ArrowBack,
    closeIcon
  }
})
export default class MobileAutoComplete extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  TextFieldMixin,
  AutoCompleteMixin
) {
  @Prop({ required: false, default: true })
  isClearable!: boolean;
  @VModel({ type: [String, Array] })
  field!: string | Array<string> | Array<AutoCompleteItem>;
  @Prop({ required: false })
  isAsync!: boolean;
  @Prop({ required: false })
  asyncCallback!: (val: string) => Promise<void>;
  private isOpen: boolean;
  private searchText = "";
  constructor() {
    super();
    this.isOpen = false;
  }

  get showClearButton(): boolean {
    if (this.isClearable && !this.disabled) {
      if (Array.isArray(this.field)) {
        return this.field.length > 0;
      } else if (this.field) {
        return this.field.length > 0;
      } else {
        return false;
      }
    }
    return false;
  }

  open(): void {
    if (!this.isDesktop) {
      if (Object.keys(this.$route.query).length > 0) {
        this.$router.push(
          `${this.$route.fullPath}&MobileAutoComplete=${this.name}`
        );
      } else {
        this.$router.push(
          `${this.$route.path}?MobileAutoComplete=${this.name}`
        );
      }
    }
  }
  clearField(): void {
    if (Array.isArray(this.field)) {
      this.field = [];
    } else {
      this.field = "";
    }
  }
  close(): void {
    this.$router.back();
  }

  @Watch("searchText")
  async triggerCallback(val: string): Promise<void> {
    if (this.isAsync) {
      this.isLoading = true;
      await this.asyncCallback(val);
      this.isLoading = false;
    }
  }

  @Watch("$route.fullPath")
  watchRoutes(): void {
    if (this.$route.query.MobileAutoComplete === this.name) {
      if (this.isOpen === false) {
        this.isOpen = true;
      }
    } else if (this.isOpen) {
      this.isOpen = false;
    }
  }

  mounted(): void {
    if (this.$route.query.MobileAutoComplete === this.name) {
      this.$router.replace(
        this.$route.fullPath.replace(`MobileAutoComplete=${this.name}`, "")
      );
    }
  }

  get finalRules(): ValidationRules {
    if (this.rules) {
      return [...this.rules, this.validateLength];
    }
    return [this.validateLength];
  }

  private validateLength(): true | string {
    if (!this.multiple) {
      if (this.minLength !== 0) {
        if (!this.field) {
          return this.minLengthMessage;
        }
      }
    } else {
      if (this.minLength !== 0) {
        if (!this.field) {
          return this.minLengthMessage;
        } else if (this.field.length < this.minLength) {
          return this.minLengthMessage;
        }
      }
      if (this.maxLength !== 0) {
        if (this.field) {
          if (this.field.length > this.maxLength) {
            return this.maxLengthMessage;
          }
        }
      }
    }
    return true;
  }
  @Watch("field")
  watchValue() {
    this.searchText = "";
    if (this.field && this.isOpen) {
      this.$router.back();
    }
  }
}
