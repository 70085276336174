import { DB } from "@/firebase";
import { fromFirestore } from "@/utils/parser";
import { BaseUser, USERS_TABLE_NAME } from "@sportango/backend";
import {
  collection,
  FieldPath,
  getDocs,
  query,
  where
} from "firebase/firestore";
import { ActionTree } from "vuex";
import { RootState } from "../types";

export const playerActions: ActionTree<RootState, RootState> = {
  async getCurrentCoachPlayers({ commit, getters }) {
    if (getters.currentUser) {
      commit(
        "coachPlayers",
        await fetchPlayersForCoach(
          getters.currentUser.uid,
          getters.featureFlags["coach-players"]
        )
      );
    }
  }
};

async function fetchPlayersForCoach(
  coachId: string,
  playersAttachedToCoaches: boolean
): Promise<Array<BaseUser>> {
  if (playersAttachedToCoaches) {
    const q = query(
      collection(DB, USERS_TABLE_NAME),
      where(
        new FieldPath("additionalInfo", "coaches"),
        "array-contains",
        coachId
      )
    );
    const { docs } = await getDocs(q);
    return docs.map((d) => fromFirestore<BaseUser>(d, "uid"));
  } else {
    // pending
    // First get all events where this coach is available
    // Then create a unique set of playerIds and fetch those players
  }
  return [];
}
