import { LoadingMixin } from "@/mixins/Helpers";

export function WatchLoading(type: "global" | "local" = "local") {
  return function (
    target: LoadingMixin,
    key: string | symbol,
    descriptor: PropertyDescriptor
  ) {
    const original = descriptor.value;
    descriptor.value = async function (this: LoadingMixin, ...args: any[]) {
      this.setLoading(true, type);
      const result = await original.apply(this, args);
      this.setLoading(false, type);
      return result;
    };
  };
}
