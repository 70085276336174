import { fromFirestore } from "@/utils/parser";
import {
  AppUser,
  BaseUser,
  CreateCustomerStripeAccount,
  UpdateUserSubscriptionRequest,
  UserPermissions,
  USERS_TABLE_NAME
} from "@sportango/backend";
import { onAuthStateChanged, User } from "firebase/auth";
import { collection, doc, onSnapshot, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { LDClient, LDUser } from "launchdarkly-js-client-sdk";
import { Auth, DB, Functions } from ".";

export async function parseAppUser(user: User): Promise<AppUser> {
  const tokenResult = await user.getIdTokenResult(true);
  const permissions: UserPermissions = {
    hasAdminAccess: !!tokenResult.claims.hasAdminAccess,
    hasCoachAccess: !!tokenResult.claims.hasCoachAccess,
    hasPlayerAccess: !!tokenResult.claims.hasPlayerAccess
  };
  if (
    (permissions.hasAdminAccess ||
      permissions.hasCoachAccess ||
      permissions.hasPlayerAccess) === false
  ) {
    permissions.hasPlayerAccess = true;
  }
  const userData = await fetchUserUntilVerified(user.uid);
  const newUser: AppUser = {
    ...user,
    permissions: userData.permissions || permissions,
    additionalInfo: {
      ...userData.additionalInfo,
      alternateContacts: userData.additionalInfo?.alternateContacts || []
    },
    phoneNumber: userData.phoneNumber || null,
    displayName: userData.displayName || null,
    photoURL: userData.photoURL || null,
    stripeCustomerId: userData.stripeCustomerId
  };
  if (
    !newUser.additionalInfo?.firstName &&
    !newUser.additionalInfo?.lastName &&
    newUser.displayName !== null
  ) {
    const [first, last] = newUser.displayName.split(" ");
    newUser.additionalInfo = {
      ...newUser.additionalInfo,
      firstName: first,
      lastName: last
    };
  }
  await syncEmailVerifiedData(newUser, userData);
  return newUser;
}

export function resolveUserFromAuthState(): Promise<User | null> {
  return new Promise((resolve) => {
    const subscription = onAuthStateChanged(Auth, (user) => {
      if (user) {
        resolve(user);
        // Calling this will unsubscribe this listener
        subscription();
      } else {
        resolve(null);
      }
    });
  });
}

export async function getCurrentAppUser(): Promise<AppUser | null> {
  const appUser = await resolveUserFromAuthState();
  if (appUser) {
    return await parseAppUser(appUser);
  } else {
    return null;
  }
}

export function convertUser(user: AppUser): BaseUser {
  return {
    ...user,
    // This is because any user that logs in cannot be disabled
    disabled: false,
    displayName: user.displayName || undefined,
    email: user.email || undefined,
    phoneNumber: user.phoneNumber || undefined,
    photoURL: user.photoURL || undefined
  };
}

/**
 * This function is used to hold the application until backend has created the
 * database entry for user successfully
 *
 * @param uid Id of the user
 * @returns {BaseUser}
 */
export function fetchUserUntilVerified(uid: string): Promise<BaseUser> {
  return new Promise((resolve) => {
    const subscription = onSnapshot(
      doc(collection(DB, USERS_TABLE_NAME), uid),
      (d) => {
        const data = d.data();
        if (data) {
          const user = fromFirestore<BaseUser>(data, "uid");
          if (user.emailVerified !== undefined) {
            subscription();
            resolve(user);
          }
        }
      }
    );
  });
}

/**
 * Syncs the value for email verified between auth and DB
 *
 * @param appUser User obj from auth
 * @param userData User obj from database
 */
async function syncEmailVerifiedData(appUser: AppUser, userData: BaseUser) {
  if (appUser.emailVerified && !userData.emailVerified) {
    await updateDoc(doc(collection(DB, USERS_TABLE_NAME), userData.uid), {
      emailVerified: true
    });
  }
}

export async function triggerUpdateIfStripeIdMissing(
  userData: AppUser,
  merchantId: string
) {
  if (!userData.stripeCustomerId && userData.permissions.hasPlayerAccess) {
    const payload: CreateCustomerStripeAccount = {
      email: userData.email || "",
      userId: userData.uid,
      merchantId
    };
    if (userData.displayName) {
      payload.displayName = userData.displayName;
    }
    if (userData.phoneNumber) {
      payload.phoneNumber = userData.phoneNumber;
    }
    await httpsCallable<CreateCustomerStripeAccount, { isSuccess: boolean }>(
      Functions,
      "createStripeAccount"
    )(payload);
    window.location.reload();
  }
}

export async function triggerSubscriptionUpdate(
  userData: AppUser,
  merchantId: string
) {
  if (userData.stripeCustomerId && userData.permissions.hasPlayerAccess) {
    const payload: UpdateUserSubscriptionRequest = {
      uid: userData.uid,
      accountId: merchantId,
      customerId: userData.stripeCustomerId
    };
    const { data: response } = await httpsCallable<
      UpdateUserSubscriptionRequest,
      { isSuccess: boolean }
    >(
      Functions,
      "updateUserSubscriptions"
    )(payload);
    console.log(response);
  }
}

export async function identifyUserForLaunchDarkly(
  client: LDClient,
  user: AppUser
) {
  const ldUser: LDUser = {
    key: user.uid,
    firstName: user.additionalInfo?.firstName,
    lastName: user.additionalInfo?.lastName,
    name: user.displayName || undefined
  };
  if (user.email) {
    ldUser.email = user.email;
  }
  if (user.photoURL) {
    ldUser.avatar = user.photoURL;
  }

  await client.identify(ldUser);
}
