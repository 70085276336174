













































































































import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import Logo from "@sportango/site-configuration/out/src/assets/logo.svg";
import { MenuItem } from "./types";
import NavLink from "./NavLink.vue";
import { CurrentUserMixin } from "@/mixins/Helpers";
import UserAddIcon from "@/assets/icons/saxcons/user-add-linear.svg";
import UserSwitchIcon from "@/assets/icons/saxcons/user-switch-linear.svg";
import ProfileList from "../users/ProfileList.vue";
import UserAvatar from "@/components/users/UserAvatar.vue";

@Component({
  components: {
    UserAvatar,
    ProfileList,
    Logo,
    NavLink,
    UserAddIcon,
    UserSwitchIcon
  }
})
export default class Drawer extends mixins(CurrentUserMixin) {
  @Prop({ required: true })
  private isDesktop: boolean | undefined;

  @Prop({ required: true })
  private menuItems!: Array<MenuItem>;
}
