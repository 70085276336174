import router from "@/router";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import Vue from "vue";

Sentry.init({
  Vue,
  environment: "production",
  enabled: process.env.NODE_ENV === "production",
  dsn: "https://9ee388a4bc984a9c86139d1df0da5d1e@o4504172762234880.ingest.sentry.io/4504172967690240",
  release: "fe-release",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ["localhost", "app.sportango.co"]
    })
  ],
  logErrors: process.env.NODE_ENV === "production" ? false : true,
  debug: process.env.NODE_ENV !== "production",
  tracesSampleRate: process.env.NODE_ENV === "production" ? 0.5 : 1,
  tracingOptions: {
    trackComponents: true
  },
  attachProps: true,
  attachStacktrace: true
});
