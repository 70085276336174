

































































import AppDialog from "@/components/Navigation/AppDialog.vue";
import { EmitClose } from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { IAlternativeContacts } from "@/types/AlternativeContacts";
import { Header } from "@/types/Table";
import Component from "vue-class-component";
import { Mixins, Prop } from "vue-property-decorator";
import EditIcon from "@/assets/icons/saxcons/edit-2-linear.svg";
import DeleteIcon from "@/assets/icons/saxcons/trash-linear.svg";
import MenuIcon from "@/assets/icons/saxcons/three-dots-vertical.svg";
@Component({
  components: {
    AppDialog,
    EditIcon,
    DeleteIcon,
    MenuIcon
  }
})
export default class AlternativeContactList extends Mixins(
  EmitClose,
  ResponsiveMixin
) {
  @Prop({ required: true })
  showDialog!: false;
  @Prop({ required: true })
  isLoading!: false;
  @Prop({ required: false, default: () => [] })
  AlternativeContacts!: [];
  get alternativeContactHeaders(): Array<Header<IAlternativeContacts>> {
    return [
      {
        value: "name",
        text: "Name",
        align: "start",
        width: "130"
      },
      {
        value: "email",
        text: "Email",
        align: "start",
        width: "130"
      },
      {
        value: "phoneNumber",
        text: "Phone",
        width: "180",
        align: "start"
      },
      {
        value: "actions",
        text: "Actions",
        sortable: false,
        align: "end",
        width: "150"
      }
    ];
  }
}
