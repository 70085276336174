import {
  AppUser,
  BaseUser,
  Court,
  Event,
  Private,
  Program,
  StripeMerchantInfo
} from "@sportango/backend";
import { GetterTree } from "vuex";
import { FeatureFlags, RootState } from "./types";

const getters: GetterTree<RootState, RootState> = {
  isLoading(state): boolean {
    return state.isLoading;
  },
  currentUser(state): AppUser | undefined {
    return state.currentUser;
  },

  users(state): Array<BaseUser> {
    return state.users;
  },
  profiles(state): Array<BaseUser> {
    return state.profiles;
  },
  programs(state): Array<Program> {
    return state.programs;
  },
  events(state): Array<Event> {
    return state.events;
  },
  allProgramsFetched(state): boolean {
    return state.allProgramsFetched;
  },
  privateLessons(state): Array<Private> {
    return state.privateLessons;
  },
  courts(state): Array<Court> {
    if (state.featureFlags.courts) {
      return state.courts;
    }
    return [];
  },
  featureFlags(state): FeatureFlags {
    return state.featureFlags;
  },
  coachPlayers(state): Array<BaseUser> {
    return state.coachPlayers;
  },
  merchantInfo(state): StripeMerchantInfo | undefined {
    return state.merchantInfo;
  },
  stripeClientLoaded(state): boolean {
    return state.stripeClientLoaded;
  },
  transactions(state) {
    return state.transactions;
  },
  invites(state) {
    return state.invites;
  },
  memberships(state) {
    return state.memberships;
  },
  subscriptions(state) {
    return state.subscriptions;
  },
  programMakeups(state) {
    return state.programMakeups;
  }
};

export default getters;
