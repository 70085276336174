import { AutoCompleteItem } from "@/components/Inputs/mixins";
import { DB } from "@/firebase";
import { forFirestore, fromFirestore } from "@/utils/parser";
import { getFinalArray } from "@/utils/validation";
import {
  BaseUser,
  UserInvite,
  UserPermissions,
  USERS_TABLE_NAME,
  USER_INVITES
} from "@sportango/backend";
import {
  collection,
  deleteDoc,
  doc,
  documentId,
  endAt,
  FieldPath,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  QueryConstraint,
  setDoc,
  startAt,
  updateDoc,
  where
} from "firebase/firestore";
import { chunk } from "lodash";
import { ActionContext, ActionTree } from "vuex";
import { RootState } from "../types";

export const usersActions: ActionTree<RootState, RootState> = {
  async getUsers({ commit, getters }, startNumber?: number) {
    if (startNumber === undefined) {
      startNumber = 0;
    }
    const q = query(collection(DB, USERS_TABLE_NAME), orderBy("email"));
    const docSnapShots = await getDocs(q);
    commit("users", [
      ...getters.users,
      ...docSnapShots.docs.map((d) => fromFirestore<BaseUser>(d, "uid"))
    ]);
  },

  async updateUser(
    { commit, getters, dispatch }: ActionContext<RootState, RootState>,
    data: {
      uid: string;
      newData: Partial<BaseUser>;
      avoidProfiles?: boolean;
    }
  ): Promise<void> {
    const ref = doc(DB, USERS_TABLE_NAME, data.uid);
    if (data.newData.phoneNumber) {
      data.newData.phoneNumber = convertUserPhoneNumber(
        data.newData.phoneNumber,
        "db"
      );
    }
    if (
      data.newData.additionalInfo?.parents &&
      data.newData.additionalInfo.parents.length > 0
    ) {
      data.newData.additionalInfo.parents =
        data.newData.additionalInfo.parents.map((p) => {
          if (p.parentPhone && !p.parentPhone.startsWith("+")) {
            p.parentPhone = convertUserPhoneNumber(p.parentPhone, "db");
          }
          return p;
        });
    }
    // Update phone number for all profiles
    if (
      (data.newData.phoneNumber || data.newData.paymentTermsAccepted) &&
      !data.avoidProfiles
    ) {
      const secondaryProfiles = getters.profiles.filter(
        (p) => p.uid !== data.uid
      );
      if (secondaryProfiles.length > 0) {
        const secondaryPayload: Partial<BaseUser> = {};
        if (data.newData.phoneNumber) {
          secondaryPayload.phoneNumber = data.newData.phoneNumber;
        }
        if (data.newData.paymentTermsAccepted) {
          secondaryPayload.paymentTermsAccepted =
            data.newData.paymentTermsAccepted;
        }
        await Promise.all(
          secondaryProfiles.map((p) =>
            dispatch("updateUser", {
              uid: p.uid,
              newData: secondaryPayload,
              avoidProfiles: true
            })
          )
        );
      }
    }
    await updateDoc(ref, forFirestore(data.newData));
    commit(
      "users",
      getters.users.map((u: BaseUser) => {
        if (u.uid === data.uid) {
          return {
            ...u,
            ...data.newData
          };
        }
        return u;
      })
    );
    if (data.uid === getters.currentUser?.uid) {
      commit("currentUser", {
        ...getters.currentUser,
        ...data.newData
      });
    }
    commit(
      "profiles",
      getters.profiles.map((p) => {
        if (p.uid === data.uid) {
          return {
            ...p,
            ...data.newData
          };
        }
        return p;
      })
    );
  },

  async fetchUserProfiles({ commit }, email: string): Promise<void> {
    const q = query(
      collection(DB, USERS_TABLE_NAME),
      where("email", "==", email)
    );
    const { docs } = await getDocs(q);
    commit(
      "profiles",
      docs.map((d) => fromFirestore<BaseUser>(d, "uid"))
    );
  },

  async addUserProfile(
    { commit, getters },
    payload: {
      uid: string;
      newData: Partial<BaseUser>;
    }
  ): Promise<void> {
    const finalPayload: Partial<BaseUser> = {
      ...payload.newData,
      permissions: getters.currentUser?.permissions || {
        hasAdminAccess: false,
        hasCoachAccess: false,
        hasPlayerAccess: true
      },
      disabled: false,
      emailVerified: true,
      email: getters.currentUser?.email || undefined,
      phoneNumber: getters.currentUser?.phoneNumber || undefined,
      stripeCustomerId: getters.currentUser?.stripeCustomerId,
      isVirtual: true,
      uid: payload.uid,
      registeredDate: Number(new Date())
    };
    if (getters.currentUser?.additionalInfo) {
      finalPayload.additionalInfo = {
        ...finalPayload.additionalInfo,
        parents: getters.currentUser.additionalInfo.parents,
        acceptedTerms: true,
        coaches: [...(getters.currentUser.additionalInfo.coaches || [])]
      };
    }
    await setDoc(
      doc(collection(DB, USERS_TABLE_NAME), payload.uid),
      forFirestore(finalPayload)
    );
    commit("profiles", [
      ...getters.profiles,
      {
        ...payload.newData,
        ...{
          ...finalPayload,
          uid: payload.uid
        }
      }
    ]);
  },

  async getUsersById(
    { commit, getters, dispatch },
    uid: string | Array<string>
  ) {
    if (!Array.isArray(uid)) {
      uid = [uid];
    }
    uid = Array.from(
      new Set(
        uid
          .filter((u) => getters.users.find((us) => us.uid == u) === undefined)
          .filter((u) => u.trim().length > 0)
      )
    );
    if (uid.length > 0) {
      if (uid.length > 5) {
        await Promise.allSettled(
          chunk(uid, 5).map((u) => dispatch("getUsersById", u))
        );
      } else {
        const q = query(
          collection(DB, USERS_TABLE_NAME),
          where(documentId(), "in", uid)
        );
        const { docs } = await getDocs(q);
        commit(
          "users",
          getFinalArray<BaseUser>(
            getters.users,
            docs.map((d) => fromFirestore<BaseUser>(d, "uid")),
            "uid"
          )
        );
      }
    }
    const users = await Promise.all(
      uid
        .filter((id) => !getters.users.find((u) => u.uid === id))
        .map(getUserById)
    );
    commit("users", getFinalArray<BaseUser>(getters.users, users, "uid"));
  },

  async getAllCoaches({ commit, getters }) {
    const q = query(
      collection(DB, USERS_TABLE_NAME),
      where(new FieldPath("permissions", "hasCoachAccess"), "==", true)
    );
    const { docs } = await getDocs(q);
    commit(
      "users",
      getFinalArray<BaseUser>(
        getters.users,
        docs.map((d) => fromFirestore<BaseUser>(d, "uid")),
        "uid"
      )
    );
  },

  async getAllAdmins({ commit, getters }) {
    const q = query(
      collection(DB, USERS_TABLE_NAME),
      where(new FieldPath("permissions", "hasAdminAccess"), "==", true)
    );
    const { docs } = await getDocs(q);
    commit(
      "users",
      getFinalArray<BaseUser>(
        getters.users,
        docs.map((d) => fromFirestore<BaseUser>(d, "uid")),
        "uid"
      )
    );
  },

  async getAllInvites({ commit, getters }): Promise<void> {
    let invites: UserInvite[] = [];
    if (getters.featureFlags["coach-merchants"] && getters.currentUser) {
      // get all user invites for coach
      invites = (
        await getDocs(
          query(
            collection(DB, USER_INVITES),
            where("coachId", "==", getters.currentUser.uid)
          )
        )
      ).docs.map((d) => fromFirestore<UserInvite>(d, "id"));
    } else {
      // get all invites
      invites = (await getDocs(query(collection(DB, USER_INVITES)))).docs.map(
        (d) => fromFirestore<UserInvite>(d, "id")
      );
    }
    commit("invites", invites);
  },

  async deleteInvite({ commit, getters }, id: string): Promise<void> {
    await deleteDoc(doc(collection(DB, USER_INVITES), id));
    commit(
      "invites",
      getters.invites.filter((i) => i.id !== id)
    );
  },

  async resendInvite({ commit, getters }, id: string): Promise<void> {
    const invite = getters.invites.find((i) => i.id === id);
    if (invite) {
      invite.emailSendCount += 1;
      const payload: Partial<UserInvite> = {
        emailSendCount: invite.emailSendCount
      };
      await updateDoc(doc(collection(DB, USER_INVITES), id), payload);
      commit(
        "invites",
        getters.invites.map((i) => {
          if (i.id === invite.id) {
            return invite;
          }
          return i;
        })
      );
    }
  }
};

export async function getUserById(uid: string): Promise<BaseUser> {
  const res = await getDoc(doc(collection(DB, USERS_TABLE_NAME), uid));
  return {
    ...(res.data() as BaseUser),
    uid: uid
  };
}

export type USER_TYPES = "PLAYER" | "COACH" | "ADMIN";

export function shouldAllowUser(
  userType: USER_TYPES,
  permissions: Partial<UserPermissions>
): boolean {
  switch (userType) {
    case "ADMIN":
      return permissions.hasAdminAccess || false;
    case "COACH":
      return permissions.hasCoachAccess || false;
    case "PLAYER":
      return permissions.hasPlayerAccess || false;
  }
}

export async function findUsersForAutocomplete(
  searchString: string,
  userType: USER_TYPES,
  maxResults: number
): Promise<Array<AutoCompleteItem>> {
  return (await findUsers(searchString, userType, maxResults)).map((u) => {
    const text = u.displayName ? u.displayName : u.email ? u.email : "";
    const res: AutoCompleteItem = {
      text: text,
      value: u.uid
    };
    return res;
  });
}

export async function findUsers(
  searchString: string,
  userType: USER_TYPES,
  maxResults: number,
  excludeDisabled = true
): Promise<Array<BaseUser>> {
  let fieldPath: FieldPath;
  switch (userType) {
    case "ADMIN":
      fieldPath = new FieldPath("permissions", "hasAdminAccess");
      break;
    case "COACH":
      fieldPath = new FieldPath("permissions", "hasCoachAccess");
      break;
    case "PLAYER":
      fieldPath = new FieldPath("permissions", "hasPlayerAccess");
      break;
  }
  const queries: QueryConstraint[] = [
    orderBy("displayName"),
    startAt(searchString),
    endAt(`${searchString}\uf8ff`),
    where(fieldPath, "==", true)
  ];

  if (excludeDisabled) {
    queries.push(where("disabled", "==", false));
  }

  const q = query(
    collection(DB, USERS_TABLE_NAME),
    ...queries,
    limit(maxResults)
  );
  const { docs } = await getDocs(q);
  return docs.map((d) => {
    const partialUser = d.data() as BaseUser;
    return {
      ...partialUser,
      uid: d.id
    };
  });
}

export function convertUserPhoneNumber(
  phoneNumber: string,
  mode: "app" | "db"
): string {
  if (mode === "db") {
    return (
      "+1" +
      phoneNumber
        .replaceAll(" ", "")
        .replaceAll("(", "")
        .replaceAll(")", "")
        .replaceAll("-", "")
    );
  } else if (mode === "app") {
    phoneNumber = phoneNumber.replaceAll("+1", "");
    return `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
      3,
      6
    )}-${phoneNumber.substring(6, 10)}`;
  } else {
    return phoneNumber;
  }
}
