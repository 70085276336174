







































































































































































































































































































import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import DialogDatePicker from "@/components/Inputs/DialogDatePicker.vue";
import UsersAutoComplete from "../Navigation/UsersAutoComplete.vue";
import PriceIcon from "@/assets/icons/saxcons/dollar-square-broken.svg";
import AlternativeContact from "./AlternativeContact.vue";
import CommunicationsCard from "./CommunicationCard.vue";
import EmailIcon from "@/assets/icons/saxcons/sms-linear.svg";
import {
  SportangoTextField,
  SportangoSelect,
  SportangoTextArea
} from "@/components/Inputs/overrides";
import PictureUploadButton from "@/components/users/PictureUploadButton.vue";
import { ProfileForm } from "@/mixins/Profile";
import MultiInput from "@/components/Inputs/MultiInput.vue";

@Component({
  name: "user-profile-form",
  components: {
    MultiInput,
    PriceIcon,
    UsersAutoComplete,
    DialogDatePicker,
    SportangoTextField,
    SportangoSelect,
    SportangoTextArea,
    PictureUploadButton,
    AlternativeContact,
    CommunicationsCard,
    EmailIcon
  }
})
export default class UserProfileForm extends mixins(
  ResponsiveMixin,
  ProfileForm
) {
  isDialog = false;
}
