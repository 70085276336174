



















































































import Component, { mixins } from "vue-class-component";
import PwaConfig from "@sportango/site-configuration/out/pwa";
import ArrowIcon from "@/assets/icons/saxcons/arrow-right-plain-linear.svg";
import { BaseUser } from "@sportango/backend";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { WatchLoading } from "@/decorators/Loading";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { Prop } from "vue-property-decorator";
import { UserPaymentMethodsMixin } from "@/components/Payments/PaymentMethodsMixin";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

@Component({
  name: "payment-terms",
  components: {
    LottieAnimation,
    AppDialog,
    ArrowIcon
  }
})
export default class PaymentTerms extends mixins(
  CurrentUserMixin,
  LoadingMixin,
  ResponsiveMixin,
  UserPaymentMethodsMixin
) {
  @Prop({ required: false, default: false })
  hideCheck!: boolean;
  @Prop({ required: false })
  saveMethodsDirectly!: boolean;

  termsAccepted = false;

  constructor() {
    super();
    this.returnUrl = location.origin;
  }
  get terms(): string[] {
    return PwaConfig.paymentTerms;
  }

  async mounted() {
    this.termsAccepted = this.currentUserProfile?.paymentTermsAccepted || false;
  }

  async processClick() {
    if (this.saveMethodsDirectly) {
      await this.addPaymentMethod();
    } else {
      this.savePaymentTerms();
    }
  }

  @WatchLoading()
  async savePaymentTerms() {
    if (this.currentUser) {
      const payload: {
        uid: string;
        newData: Partial<BaseUser>;
      } = {
        uid: this.currentUser?.uid,
        newData: {
          paymentTermsAccepted: true
        }
      };
      await this.$store.dispatch("updateUser", payload);
      this.$router.push("/profile/payments");
    }
  }
}
