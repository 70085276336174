var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-auto-complete-container"},[(!_vm.isDesktop)?_c('v-dialog',{attrs:{"fullscreen":""},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('div',{staticClass:"mobile-auto-complete-dialog py-3 px-3 bg-app h-100"},[_c('v-autocomplete',{attrs:{"autofocus":"","label":_vm.label,"placeholder":_vm.placeholder,"persistent-placeholder":"","chips":_vm.field ? true : false,"items":_vm.items,"rules":_vm.rules,"hide-selected":"","auto-select-first":"","loading":_vm.isLoading,"search-input":_vm.searchText,"multiple":_vm.multiple,"dense":_vm.dense,"rounded":_vm.rounded},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(){return [_c('v-btn',{staticStyle:{"margin-top":"-12px"},attrs:{"icon":"","elevation":"0","height":"40px","width":"40px"},on:{"click":_vm.close}},[_c('arrow-back')],1)]},proxy:true},(_vm.showClearButton)?{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","elevation":"0","height":"40px","width":"40px"},on:{"click":_vm.clearField}},[_c('closeIcon')],1)]},proxy:true}:null],null,true),model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}})],1)]):_vm._e(),_c('div',{staticClass:"p-relative"},[(!_vm.isOpen)?_c('v-autocomplete',{attrs:{"outlined":"","hide-selected":"","auto-select-first":"","label":_vm.label,"placeholder":_vm.placeholder,"persistent-placeholder":"","chips":_vm.field ? true : false,"items":_vm.items,"multiple":_vm.multiple,"rules":_vm.finalRules,"loading":_vm.isLoading,"search-input":_vm.searchText,"hide-no-data":!_vm.isDesktop,"dense":_vm.dense,"rounded":_vm.rounded,"disabled":_vm.disabled,"hide-details":_vm.hideDetails,"menu-props":{
        closeOnClick: true,
        closeOnContentClick: true,
        disableKeys: true,
        openOnClick: false,
        maxHeight: 304,
        offsetY: true,
        offsetOverflow: true,
        transition: false
      }},on:{"update:searchInput":function($event){_vm.searchText=$event},"update:search-input":function($event){_vm.searchText=$event}},scopedSlots:_vm._u([(_vm.showClearButton)?{key:"append",fn:function(){return [_c('v-btn',{attrs:{"icon":"","elevation":"0","height":"40px","width":"40px"},on:{"click":_vm.clearField}},[_c('closeIcon')],1)]},proxy:true}:null],null,true),model:{value:(_vm.field),callback:function ($$v) {_vm.field=$$v},expression:"field"}}):_vm._e(),(!_vm.isDesktop)?_c('v-btn',{staticClass:"btn-trigger h-100",staticStyle:{"width":"calc(100% - 10%)"},attrs:{"disabled":_vm.disabled},on:{"click":_vm.open}},[_vm._v("Trigger")]):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }