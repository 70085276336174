










































































































import { CurrentUserMixin, EmitClose } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import { MenuItem } from "./types";
import UserAddIcon from "@/assets/icons/saxcons/user-add-linear.svg";
import UserSwitchIcon from "@/assets/icons/saxcons/user-switch-linear.svg";
import AppDialog from "./AppDialog.vue";
import ProfileList from "../users/ProfileList.vue";
import NavLink from "./NavLink.vue";
import LogoutIcon from "@/assets/icons/saxcons/logout-linear.svg";
import { signOut } from "@firebase/auth";
import { Auth } from "@/firebase";

@Component({
  name: "mobile-drawer",
  components: {
    NavLink,
    ProfileList,
    AppDialog,
    UserAddIcon,
    UserSwitchIcon,
    LogoutIcon
  }
})
export default class MobileDrawer extends mixins(CurrentUserMixin, EmitClose) {
  @Prop({ required: true })
  menuItems!: Array<MenuItem>;

  dialogOpen = false;

  get remainingMenuItems(): Array<MenuItem> {
    const used = this.menuItems.slice(0, 4);
    return this.menuItems.filter((m) => !used.find((u) => u.name === m.name));
  }
  async logOut() {
    await signOut(Auth);
  }
}
