var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{class:_vm.isDesktop ? 'desktop-drawer' : 'mobile-drawer',attrs:{"mini-variant-width":"90","expand-on-hover":_vm.isDesktop,"value":true,"permanent":""}},[_c('v-list',[_c('v-list-item',{staticClass:"px-2 justify-start"},[_c('div',{staticClass:"d-flex justify-center w-100"},[_c('v-list-item-avatar',{staticClass:"mx-0",attrs:{"height":"56","width":"56","rounded":"0"}},[_c('logo',{attrs:{"viewBox":"0 0 40 38"}})],1)],1)])],1),_c('v-divider'),_c('v-spacer'),_c('v-list',{staticClass:"px-4",attrs:{"nav":""}},_vm._l((_vm.menuItems),function(item){return _c('nav-link',{key:item.path,attrs:{"path":item.path,"component":item.name,"title":item.text || item.name}})}),1),_c('v-spacer'),_c('v-divider'),_c('v-list',{staticClass:"px-4",attrs:{"nav":""}},[(
        _vm.isCurrentUserOnlyPlayer &&
        _vm.$store.getters.featureFlags['secondary-profiles']
      )?_c('v-list-item',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"px-0 nav-item",attrs:{"to":"/add-profile"}},[_c('v-list-item-icon',[_c('user-add-icon',{staticStyle:{"height":"32px","width":"32px"},attrs:{"viewBox":"0 0 24 24"}})],1),_c('v-list-item-title',[_vm._v(" Add Profile ")])],1):_vm._e(),(
        _vm.isCurrentUserOnlyPlayer &&
        _vm.hasProfiles &&
        _vm.$store.getters.featureFlags['secondary-profiles']
      )?_c('v-menu',{attrs:{"right":"","offset-y":"","content-class":"desktop-profiles-menu"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"px-0 nav-item"},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('user-switch-icon',{staticStyle:{"height":"32px","width":"32px","transform":"scale(2.5) translateY(-4.5px)"}})],1),_c('v-list-item-title',[_vm._v(" Switch Profile ")])],1)]}}],null,false,1048109503)},[_c('profile-list')],1):_vm._e(),(_vm.currentUser)?_c('v-list-item',{staticClass:"nav-item d-flex align-center justify-start",attrs:{"link":"","to":"/profile/current"}},[_c('v-list-item-icon',[_c('user-avatar',{attrs:{"photoURL":_vm.currentUser.photoURL,"size":36}})],1),_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.currentUser ? _vm.currentUser.displayName || _vm.currentUser.email : "Add Your Name")+" ")])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }