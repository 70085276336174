import Vue from "vue";
import Component from "vue-class-component";

@Component
export class SW extends Vue {
  public registration: ServiceWorkerRegistration | undefined;
  public updateExists: boolean;
  public isOffline: boolean;
  public refreshing: boolean;
  constructor() {
    super();
    this.updateExists = false;
    this.isOffline = false;
    this.refreshing = false;
  }

  created(): void {
    document.addEventListener("swUpdated", this.updateAvailable);
    document.addEventListener("offline", this.offline);
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      this.refreshing = true;
      window.location.reload();
    });
  }

  public updateAvailable(event: any): void {
    this.registration = event.detail;
    this.updateExists = true;
  }

  public offline(): void {
    this.isOffline = true;
  }
}
