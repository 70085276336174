import { Functions } from "@/firebase";
import { CurrentUserMixin } from "@/mixins/Helpers";
import { StripeClient } from "@/mixins/Stripe";
import {
  StripeBankPaymentMethod,
  StripeCardPaymentMethod,
  StripeSetupIntent
} from "@/types/Payment";
import { StripeElements, StripePaymentElement } from "@stripe/stripe-js";
import { httpsCallable } from "firebase/functions";
import Component, { mixins } from "vue-class-component";
import { Ref } from "vue-property-decorator";

@Component
export class UserPaymentMethodsMixin extends mixins(
  CurrentUserMixin,
  StripeClient
) {
  cards: Array<StripeCardPaymentMethod> = [];
  bankAccounts: Array<StripeBankPaymentMethod> = [];
  defaultPaymentMethod:
    | StripeCardPaymentMethod
    | StripeBankPaymentMethod
    | null = null;
  formLoading = false;
  formSubmitting = false;
  elements: StripeElements | null = null;
  paymentDialog = false;
  payment: StripePaymentElement | null = null;
  errorMessage: string | null = null;
  @Ref("payment-element")
  paymentElement!: HTMLElement;
  paymentMethodsLoaded = false;
  returnUrl: string;

  constructor() {
    super();
    this.returnUrl = location.origin + "/profile/payments/added";
  }

  get noPaymentMethods(): boolean {
    if (this.cards.length > 0 || this.bankAccounts.length > 0) {
      return false;
    }
    return true;
  }

  async fetchMethods() {
    if (
      this.currentUser?.stripeCustomerId &&
      this.$stripeClient &&
      this.$store.getters.merchantInfo?.merchantId
    ) {
      this.cards = (
        await this.$stripeClient.get<Array<StripeCardPaymentMethod>>(
          "/paymentMethods",
          {
            params: {
              customer: this.currentUser.stripeCustomerId,
              paymentMethod: "card"
            }
          }
        )
      ).data;
      this.bankAccounts = (
        await this.$stripeClient.get<Array<StripeBankPaymentMethod>>(
          "/paymentMethods",
          {
            params: {
              customer: this.currentUser.stripeCustomerId,
              paymentMethod: "us_bank_account"
            }
          }
        )
      ).data;
      if (this.cards.length + this.bankAccounts.length > 0) {
        // get default payment method
        this.defaultPaymentMethod = (
          await httpsCallable<
            {
              customer: string;
              merchantId: string;
            },
            StripeCardPaymentMethod | StripeBankPaymentMethod
          >(
            Functions,
            "getDefaultPaymentMethodForCustomer"
          )({
            customer: this.currentUser.stripeCustomerId,
            merchantId: this.$store.getters.merchantInfo.merchantId
          })
        ).data;
        // set as default if only one present
        if (this.cards.length + this.bankAccounts.length === 1) {
          await this.$stripeClient.patch(
            `/paymentMethods/${this.defaultPaymentMethod.id}/default`
          );
        }
      } else {
        this.defaultPaymentMethod = null;
      }
    }
    this.paymentMethodsLoaded = true;
  }

  async addPaymentMethod() {
    this.formLoading = true;
    this.paymentDialog = true;
    if (this.currentUser?.stripeCustomerId && this.$stripeClient) {
      const getSetupIntent = await this.$stripeClient.post<StripeSetupIntent>(
        "paymentMethods",
        {
          customer: this.currentUser.stripeCustomerId,
          methods: ["card", "us_bank_account"]
        }
      );
      this.elements = this.$stripe.elements({
        clientSecret: getSetupIntent.data.client_secret
      });
      this.payment = this.elements?.create("payment");
      this.payment?.mount(this.paymentElement);
      this.payment.on("focus", () => {
        this.errorMessage = null;
      });
    }
    // set a small timer to set form loading to false
    setTimeout(() => {
      this.formLoading = false;
    }, 3500);
  }

  async submitPaymentMethod() {
    this.formSubmitting = true;
    if (this.elements) {
      const { error } = await this.$stripe.confirmSetup({
        elements: this.elements,
        confirmParams: {
          return_url: this.returnUrl
        }
      });
      if (error) {
        this.errorMessage = error.message || null;
      }
    }
    this.formSubmitting = false;
  }
}
