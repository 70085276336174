import { AutoCompleteItem } from "./mixins";

export const PLAYER_CATEGORIES: Array<AutoCompleteItem> = [
  {
    text: "Junior(18 and under)",
    value: "junior"
  },
  {
    text: "Adult(18+)",
    value: "adult"
  }
];
