











































import Component, { mixins } from "vue-class-component";
import { Prop, VModel } from "vue-property-decorator";
import CloseIcon from "@/assets/icons/saxcons/add-linear.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { EmitClose } from "@/mixins/Helpers";

@Component({
  name: "app-dialog",
  components: {
    CloseIcon
  }
})
export default class AppDialog extends mixins(ResponsiveMixin, EmitClose) {
  // Props to be accepted
  @Prop({ default: 800 })
  private width: number | undefined;
  @VModel()
  private dialogOpen: boolean | undefined;
  @Prop({ default: false })
  private disableClose: boolean | undefined;
  @Prop({ required: false, default: false })
  persistent!: boolean;
  @Prop({ required: false, default: false })
  fullscreen!: boolean;
  @Prop({ required: false, default: "" })
  contentClass!: string;

  get isFullScreen(): boolean {
    return !this.isDesktop || this.fullscreen;
  }

  get maxContentHeight(): number {
    if (!this.isFullScreen) {
      return innerHeight * 0.9 - 2 * 24 - 2 * 16;
    }
    return innerHeight;
  }
}
