import { membershipActions } from "@/store/actions/memberships";
import { ActionTree } from "vuex";
import { RootState } from "../types";
import { eventActions } from "./events";
import { merchantActions } from "./merchant";
import { playerActions } from "./players";
import { privateLessonActions } from "./private.lessons";
import { programActions } from "./programs";
import { transactionsActions } from "./transactions";
import { usersActions } from "./users";

export const actions: ActionTree<RootState, RootState> = {
  ...programActions,
  ...usersActions,
  ...eventActions,
  ...privateLessonActions,
  ...playerActions,
  ...merchantActions,
  ...transactionsActions,
  ...membershipActions
};

export type ActionPayload =
  | RootState
  | {
      test: "";
    };
export interface ActionsDispatch {
  getEvents: ActionPayload;
  getUsers: RootState;
  getUsersById: RootState;
  updateUser: RootState;
  getProgramById: RootState;
  getPrograms: RootState;
  getProgramEvents: RootState;
  getEventsForUser: RootState;
  updateEventTimings: RootState;
  getPrivateLessonsById: RootState;
  updateEvent: RootState;
  updateProgram: RootState;
  updatePrivateLesson: RootState;
  fetchMerchantInfo: RootState;
  setupMerchantId: RootState;
}
