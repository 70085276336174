export type ValidationCallback = (v: string) => true | string;
export type ValidationRules = Array<ValidationCallback>;

export function requiredFieldRule(field: string): ValidationRules {
  return [
    (v: string): true | string => {
      return !!v || `${field} is required`;
    }
  ];
}

export function numericPositiveRule(
  field: string,
  required?: boolean
): ValidationRules {
  const numericPositive = [
    (v: string): true | string => {
      const num = Number(v);
      if (isNaN(num)) {
        return `${field} should be a number`;
      }
      if (num < 0) {
        return `${field} cannot be negative`;
      }
      return true;
    }
  ];
  if (required) {
    return [...requiredFieldRule(field), ...numericPositive];
  }
  return numericPositive;
}

export function numericPositiveIntegerRule(
  field: string,
  required?: boolean
): ValidationRules {
  return [
    ...numericPositiveRule(field, required),
    (v: string): true | string => {
      if (v === undefined) {
        v = "";
      }
      if (v.toString().split(".").length > 1) {
        return `${field} cannot be a decimal value`;
      }
      return true;
    }
  ];
}

export const EMAIL_VALIDATION_RULES: ValidationRules = [
  (v: string): true | string => {
    return !!v || "E-mail is required";
  },
  (v: string): true | string => {
    return /.+@.+\..+/.test(v) || "E-mail must be valid";
  }
];

export const PASSWORD_VALIDATION_RULES: ValidationRules = [
  (v: string): true | string => {
    return !!v || "Password is required";
  },
  (v: string): true | string => {
    return (
      v.length >= 10 || "Please use at least 10 characters in your password"
    );
  },
  (v: string): true | string => {
    return (
      RegExp("(?=.*[0-9]+)(?=.*[a-z]+)(?=.*[A-Z]+).{6,}").test(v) ||
      "Please use at least one upper case and a number"
    );
  }
];

export const WORDS = ["One", "Two", "Three", "Four", "Five", "Six", "Seven"];

/**
 * This function is used to generate final array that can be directly put in state
 *
 * @param original The original Array, you should pass getters.<val>
 * @param newVal The new Items you wanna add to the array
 * @param identifier The field in entity used to identify
 * @returns The Final Array that can be directly saved to state
 */
export function getFinalArray<T>(
  original: Array<T>,
  newVal: Array<T>,
  identifier: keyof T
): Array<T> {
  const clone = [...original];
  newVal.forEach((n) => {
    if (!clone.find((p) => p[identifier] === n[identifier])) {
      clone.push(n);
    }
  });
  clone.map((c) => {
    const newItem = clone.find((p) => p[identifier] === c[identifier]);
    return newItem || c;
  });
  return clone;
}
