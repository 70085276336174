









































import { ProfileForm } from "@/mixins/Profile";
import { Mixins, VModel, Component } from "vue-property-decorator";
import EmailIcon from "@/assets/icons/saxcons/sms-linear.svg";
import CallIcon from "@/assets/icons/saxcons/call-linear.svg";
import Divider from "@/components/common/Divider.vue";
@Component({
  name: "communicationCard",
  components: {
    EmailIcon,
    Divider,
    CallIcon
  }
})
export default class CommunicationsCard extends Mixins(ProfileForm) {
  @VModel({ type: Boolean, default: false })
  isDialog!: boolean;

  get totalContacts() {
    return (
      this.userProfileInfo.alternateContacts?.filter(
        (contacts) => contacts.phoneNumber
      ).length || 0
    );
  }
  get totalEmails() {
    return (
      this.userProfileInfo.alternateContacts?.filter(
        (contacts) => contacts.email
      ).length || 0
    );
  }
  get emailsAllowed() {
    return !this.userProfileInfo.communicationSettings?.noEmails;
  }
  set emailsAllowed(flag: boolean) {
    this.userProfileInfo.communicationSettings = {
      ...this.userProfileInfo.communicationSettings,
      noEmails: !flag
    };
  }

  get smsAllowed() {
    return !this.userProfileInfo.communicationSettings?.noSMS;
  }

  set smsAllowed(flag: boolean) {
    this.userProfileInfo.communicationSettings = {
      ...this.userProfileInfo.communicationSettings,
      noSMS: !flag
    };
  }
}
