import { options } from "@sportango/site-configuration/out/src/firebase/options";
import { initializeApp } from "firebase/app";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { getStorage } from "firebase/storage";

// Initialize Firebase
const App = initializeApp(options);

export const Auth = getAuth(App);
export const DB = getFirestore(App);
export const Storage = getStorage(App);
export const Functions = getFunctions(App);
if (
  process.env.VUE_APP_USE_EMULATORS &&
  process.env.VUE_APP_USE_EMULATORS === "true"
) {
  connectFirestoreEmulator(DB, "http://localhost", 8082);
  connectAuthEmulator(Auth, "http://localhost:8084");
}

export * from "./auth";
