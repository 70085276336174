import { DB } from "@/firebase";
import { fromFirestore } from "@/utils/parser";
import {
  Membership,
  MEMBERSHIPS_TABLE,
  Subscription,
  SUBSCRIPTIONS_TABLE
} from "@sportango/backend";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import { ActionTree } from "vuex";
import { RootState } from "../types";

export interface MembershipResponse {
  isSuccess: boolean;
  message?: string;
}

export const membershipActions: ActionTree<RootState, RootState> = {
  async getAllMemberships({ commit }) {
    const { docs } = await getDocs(collection(DB, MEMBERSHIPS_TABLE));
    commit(
      "memberships",
      docs.map((d) => fromFirestore<Membership>(d, "id"))
    );
  },

  async getUserSubscription({ commit, getters }, customerId: string) {
    const subscriptionData = await getDoc(
      doc(collection(DB, SUBSCRIPTIONS_TABLE), customerId)
    );
    if (subscriptionData.exists()) {
      commit("subscriptions", [
        ...getters.subscriptions.filter(
          (s) => s.stripeCustomerId !== customerId
        ),
        fromFirestore<Subscription>(subscriptionData, "stripeCustomerId")
      ]);
    }
  },
  async getAllSubscriptions({ commit }) {
    const { docs } = await getDocs(collection(DB, SUBSCRIPTIONS_TABLE));
    commit(
      "subscriptions",
      docs.map((d) => fromFirestore<Subscription>(d, "stripeCustomerId"))
    );
  }
};
