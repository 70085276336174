import { LaunchDarklyConfig } from "@sportango/site-configuration/out/launchDarkly";
import * as launchDarkly from "launchdarkly-js-client-sdk";
import Vue, { PluginFunction } from "vue";

const install: PluginFunction<never> = async (vue) => {
  vue.prototype.$ldClient = launchDarkly.initialize(
    LaunchDarklyConfig.clientId,
    {
      anonymous: true
    }
  );
  vue.prototype.$ld = launchDarkly;
};

Vue.use(install);
export default {
  install
};
