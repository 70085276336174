import { DB } from "@/firebase";
import { forFirestore, fromFirestore } from "@/utils/parser";
import { AppUser, SITE_CONFIG, StripeMerchantInfo } from "@sportango/backend";
import { collection, doc, getDoc, setDoc } from "firebase/firestore";
import { ActionTree } from "vuex";
import { FeatureFlags, RootState } from "../types";

export const merchantActions: ActionTree<RootState, RootState> = {
  async fetchMerchantInfo({ commit, getters }) {
    const merchantDoc = await getDoc(
      doc(
        collection(DB, SITE_CONFIG),
        getMerchantDocId(getters.featureFlags, getters.currentUser)
      )
    );
    if (merchantDoc && merchantDoc.data()) {
      const merchantConfig = fromFirestore<StripeMerchantInfo>(
        merchantDoc,
        "id"
      );
      commit("merchantInfo", merchantConfig);
    } else {
      commit("merchantInfo", {
        status: false,
        merchantId: undefined
      });
    }
  },

  /**
   * Use this when hit refresh url. i.e onboarding not complete
   *
   * @param param0
   * @param merchantId
   */
  async setupMerchantId({ commit, getters }, merchantId: string) {
    const merchantDocId = getMerchantDocId(
      getters.featureFlags,
      getters.currentUser
    );
    const newMerchantInfo: StripeMerchantInfo = {
      status: false,
      merchantId,
      id: merchantDocId
    };
    await setDoc(
      doc(collection(DB, SITE_CONFIG), merchantDocId),
      newMerchantInfo
    );
    commit("merchantInfo", {
      ...getters.merchantInfo,
      merchantId
    });
  },

  /**
   * Use this when hit return url, i.e onboarding is completed
   *
   * @param param0
   * @param newData New merchant ID to set
   */
  async updateMerchantInfo(
    { commit, getters },
    newData: Partial<StripeMerchantInfo>
  ) {
    const merchantDocId = getMerchantDocId(
      getters.featureFlags,
      getters.currentUser
    );
    await setDoc(
      doc(collection(DB, SITE_CONFIG), merchantDocId),
      forFirestore({
        ...getters.merchantInfo,
        ...newData
      })
    );
    commit("merchantInfo", {
      ...getters.merchantInfo,
      ...newData,
      id: merchantDocId
    });
  }
};

function getMerchantDocId(
  featureFlags: FeatureFlags,
  currentUser: AppUser | undefined
): string {
  if (featureFlags["coach-merchants"]) {
    if (currentUser?.permissions.hasCoachAccess) {
      return `merchant-${currentUser.uid}`;
    } else if (
      currentUser?.permissions.hasPlayerAccess &&
      currentUser.additionalInfo?.coaches &&
      currentUser.additionalInfo?.coaches?.length > 0
    ) {
      return `merchant-${currentUser.additionalInfo?.coaches[0]}`;
    }
  }
  return "merchant";
}
