












































































































































































import Vue from "vue";
import Component from "vue-class-component";

@Component({
  name: "terms-and-conditions"
})
export default class TermsAndConditions extends Vue {}
