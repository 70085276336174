




























import { CurrentUserMixin } from "@/mixins/Helpers";
import { AppUser } from "@sportango/backend";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "profile-list"
})
export default class ProfileList extends mixins(CurrentUserMixin) {
  switchProfile(profileId: string) {
    const selectedProfile = this.$store.getters.profiles.find(
      (p) => p.uid === profileId
    );
    if (selectedProfile && this.currentUser) {
      const switchProfilePayload: AppUser = {
        ...this.currentUser,
        permissions: this.currentUser.permissions,
        additionalInfo: selectedProfile.additionalInfo,
        phoneNumber: this.currentUser.phoneNumber,
        photoURL: selectedProfile.photoURL || null,
        stripeCustomerId: this.currentUser.stripeCustomerId,
        uid: selectedProfile.uid,
        displayName: selectedProfile.displayName || null
      };
      this.$store.commit("currentUser", switchProfilePayload);
    }
    this.$emit("switched");
  }
}
