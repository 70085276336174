














































































































import Component, { mixins } from "vue-class-component";
import Navigation from "@/components/Navigation/Navigation.vue";
import { ResponsiveMixin } from "@/mixins/Responsive";
import HomeProfileForm from "@/components/users/HomeProfileForm.vue";
import { LoadingMixin } from "@/mixins/Helpers";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { sendEmailVerification, signOut } from "@firebase/auth";
import { Auth, resolveUserFromAuthState } from "@/firebase";
import AppDialog from "@/components/Navigation/AppDialog.vue";
import PaymentTerms from "@/components/common/PaymentTerms.vue";
import TermsAndConditions from "./Terms.vue";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import VueCountdown from "@chenfengyuan/vue-countdown";
import SettingsAccount from "@/views/settings/SettingsAccount.vue";
import JoiningFeeSetup from "@/components/Payments/JoiningFee.vue";
import { UserPaymentMethodsMixin } from "@/components/Payments/PaymentMethodsMixin";
import { WatchLoading } from "@/decorators/Loading";
import PaymentMethodAdded from "@/views/profile/PaymentMethodAdded.vue";

@Component({
  name: "home",
  components: {
    PaymentMethodAdded,
    JoiningFeeSetup,
    SettingsAccount,
    PaymentTerms,
    TermsAndConditions,
    AppDialog,
    HomeProfileForm,
    Navigation,
    LottieAnimation,
    VueCountdown
  }
})
export default class Home extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  UserPaymentMethodsMixin
) {
  openTerms = false;

  get userNeedsRegistration(): boolean {
    if (this.currentUser) {
      if (this.currentUser.additionalInfo && this.currentUser.phoneNumber) {
        return !(
          (this.currentUser.additionalInfo.firstName !== undefined ||
            this.currentUser.additionalInfo.lastName !== undefined) &&
          this.currentUser.additionalInfo.gender &&
          this.currentUser.additionalInfo.dateOfBirth &&
          !this.neededTerms &&
          !this.needsCoach
        );
      }
    }
    return true;
  }

  get neededTerms(): boolean {
    if (this.$store.getters.featureFlags["terms-and-conditions"]) {
      return !this.currentUser?.additionalInfo?.acceptedTerms;
    }
    return false;
  }

  get needsCoach(): boolean | undefined {
    if (!this.currentUser?.permissions.hasPlayerAccess) {
      return false;
    }
    if (!this.$store.getters.featureFlags["direct-registration"]) {
      return false;
    }
    return !this.currentUser?.additionalInfo?.coaches;
  }

  get userNeedsEmailVerification(): boolean {
    if (this.currentUser) {
      return this.currentUser.emailVerified;
    }
    return true;
  }

  get showPaymentTerms(): boolean {
    if (
      this.$store.getters.currentUser &&
      this.$store.getters.merchantInfo?.merchantId
    ) {
      if (this.$store.getters.currentUser.permissions) {
        if (this.$store.getters.currentUser.permissions.hasPlayerAccess) {
          if (this.currentUserProfile) {
            return !this.currentUserProfile.paymentTermsAccepted;
          }
        }
      }
    }
    return false;
  }

  get needsPaymentMethods(): boolean {
    if (this.$store.getters.featureFlags.memberships) {
      if (this.paymentMethodsLoaded) {
        return this.noPaymentMethods;
      }
    }
    return false;
  }

  get showPaymentMethodAdded(): boolean {
    if (this.$route.query.redirect_status === "succeeded") {
      return true;
    }
    return false;
  }

  get needsJoiningFee(): boolean {
    if (this.$store.getters.merchantInfo?.feeConfig?.enableJoiningFee) {
      if (!this.currentUser?.additionalInfo?.paidJoiningFee) {
        return false;
      }
      return false;
    }
    return false;
  }

  get needsPaymentOnboarding(): boolean {
    if (
      this.$store.getters.featureFlags["coach-merchants"] &&
      this.$store.getters.currentUser?.permissions.hasCoachAccess &&
      !this.$store.getters.merchantInfo?.merchantId
    ) {
      if (
        this.currentUser?.additionalInfo?.onboarding?.coachMerchant?.status ===
          "accepted" ||
        this.currentUser?.additionalInfo?.onboarding?.coachMerchant?.status ===
          "rejected"
      ) {
        return false;
      } else if (
        this.currentUser?.additionalInfo?.onboarding?.coachMerchant?.status ===
        "differed"
      ) {
        return (
          Number(
            this.currentUser.additionalInfo.onboarding.coachMerchant
              .lastDiffered
          ) <= Number(new Date())
        );
      }
      return true;
    } else {
      return false;
    }
  }

  async sendVerificationEmail() {
    this.isLoading = true;
    const user = await resolveUserFromAuthState();
    if (user) {
      await sendEmailVerification(user);
    }
    this.isLoading = false;
  }

  async logOut() {
    await signOut(Auth);
  }

  async mounted() {
    this.$store.commit("isLoading", true);
    if (this.isCurrentUserOnlyCoach) {
      await this.$store.dispatch("getCurrentCoachPlayers");
    }
  }
  @WatchLoading("global")
  async stripeClientLoaded() {
    await this.fetchMethods();
  }
}
