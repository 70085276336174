



































import Component, { mixins } from "vue-class-component";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import { UserPaymentMethodsMixin } from "@/components/Payments/PaymentMethodsMixin";
import ArrowRight from "@/assets/icons/saxcons/arrow-right-linear.svg";
import { WatchLoading } from "@/decorators/Loading";
import { LoadingMixin } from "@/mixins/Helpers";

@Component({
  name: "joining-fee-setup",
  components: {
    LottieAnimation,
    ArrowRight
  }
})
export default class JoiningFeeSetup extends mixins(
  UserPaymentMethodsMixin,
  LoadingMixin
) {
  get feeConfig() {
    return this.$store.getters.merchantInfo?.feeConfig;
  }

  @WatchLoading()
  async stripeClientLoaded() {
    await this.fetchMethods();
  }
}
