
































































































































import Component, { mixins } from "vue-class-component";
import { LoadingMixin } from "@/mixins/Helpers";
import ArrowRightIcon from "@/assets/icons/saxcons/arrow-right-plain-linear.svg";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";
import axios from "axios";
import { Prop, Watch } from "vue-property-decorator";
import { BaseUser, StripeMerchantInfo } from "@sportango/backend";
import { BaseStripeInfo } from "@/mixins/Stripe";
import { OnboardingPayload, StripeMerchantAccountInfo } from "@/types/Payment";
import { WatchLoading } from "@/decorators/Loading";
import CurrentUserProfile from "@/views/profile/CurrentUserProfile.vue";
import dayjs from "dayjs";

@Component({
  name: "settings-account",
  components: {
    ArrowRightIcon,
    LottieAnimation
  }
})
export default class SettingsAccount extends mixins(
  LoadingMixin,
  BaseStripeInfo,
  CurrentUserProfile
) {
  @Prop({ required: false, default: false })
  skipRoute!: boolean;
  stripeMerchantAccountInfo: StripeMerchantAccountInfo | null = null;

  @WatchLoading()
  async setupPayout(): Promise<void> {
    await this.saveOnboardingStatus("accepted");
    const body: OnboardingPayload = {
      returnUrl: this.settingsUrl + "/return",
      refreshUrl: this.settingsUrl + "/refresh"
    };

    if (this.merchantInfo?.merchantId) {
      body.accountId = this.merchantInfo.merchantId;
    }

    const response = await axios.post<{ url: string }>(
      `${this.stripeApiUrl}/stripe/onboarding`,
      body
    );

    if (response.status === 201) {
      window.location.href = response.data.url;
    }
  }

  @WatchLoading()
  async saveOnboardingStatus(status: "differed" | "accepted") {
    const updateUserPayload: {
      uid: string;
      newData: Partial<BaseUser>;
    } = {
      uid: this.currentUser?.uid || "",
      newData: {
        additionalInfo: {
          ...(this.currentUser?.additionalInfo || {}),
          onboarding: {
            coachMerchant: {
              status: status,
              lastDiffered: dayjs(new Date()).add(7, "d").toDate()
            }
          }
        }
      }
    };

    await this.$store.dispatch("updateUser", updateUserPayload);
  }

  @Watch("merchantInfo")
  async fetchMerchantAccountInfo() {
    if (this.merchantInfo?.merchantId) {
      this.stripeMerchantAccountInfo = await this.getAccountInfo(
        this.merchantInfo?.merchantId
      );
      if (this.stripeMerchantAccountInfo) {
        const newMerchantData: Partial<StripeMerchantInfo> = {
          status:
            this.stripeMerchantAccountInfo.charges_enabled &&
            this.stripeMerchantAccountInfo.details_submitted &&
            this.stripeMerchantAccountInfo.payouts_enabled,
          merchantId: this.merchantInfo.merchantId
        };
        if (!this.merchantInfo.status && newMerchantData.status) {
          await this.$store.dispatch("updateMerchantInfo", newMerchantData);
        }
      }
    }
  }

  async mounted() {
    await this.fetchMerchantAccountInfo();
  }
}
