import {
  StripeBankPaymentMethod,
  StripeCardPaymentMethod,
  StripeMerchantAccountInfo
} from "@/types/Payment";
import axios, { AxiosInstance } from "axios";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, Watch } from "vue-property-decorator";
import { ResponsiveMixin } from "./Responsive";

@Component
export class BaseStripeInfo extends Vue {
  public settingsUrl: string;
  public stripeApiUrl: string;
  public stripeClient: AxiosInstance | null = null;

  constructor() {
    super();
    this.settingsUrl = `${window.location.origin}/settings/account`;
    if (process.env.NODE_ENV === "production") {
      this.stripeApiUrl = window.location.origin;
    } else {
      if (process.env.VUE_APP_STRIPE_URL) {
        this.stripeApiUrl = process.env.VUE_APP_STRIPE_URL;
      } else {
        throw new Error(
          "Please set VUE_APP_STRIPE_URL in .env.development file"
        );
      }
    }
  }

  get merchantInfo() {
    return this.$store.getters.merchantInfo;
  }

  async getAccountInfo(accountId: string): Promise<StripeMerchantAccountInfo> {
    return (
      await axios.get<StripeMerchantAccountInfo>(
        `${this.stripeApiUrl}/stripe/accounts/${accountId}`
      )
    ).data;
  }

  createStripClient(): AxiosInstance | null {
    if (this.merchantInfo && this.merchantInfo.merchantId) {
      return axios.create({
        baseURL: this.stripeApiUrl + "/stripe",
        headers: {
          "Stripe-Account": this.merchantInfo.merchantId
        }
      });
    }
    return null;
  }
}

@Component
export class StripeClient extends Vue {
  private get merchantInfo() {
    return this.$store.getters.merchantInfo;
  }
  @Watch("$store.getters.stripeClientLoaded")
  private async watchMerchantInfo(): Promise<void> {
    if (this.merchantInfo && this.merchantInfo.merchantId) {
      // assign to stripe client here
      await this.stripeClientLoaded();
    }
  }

  /** Override this to achieve th logic to want */
  async stripeClientLoaded() {
    // will be overridden by users of this mixin
  }
  async mounted() {
    if (this.merchantInfo && this.merchantInfo.merchantId) {
      await this.stripeClientLoaded();
    }
  }
}

@Component
export class StripePaymentMethod extends ResponsiveMixin {
  @Prop({ required: false, default: false })
  showOptions!: boolean;

  @Prop({ required: false, default: false })
  isDefault!: boolean;

  get showOptionsBtn() {
    return this.showOptions && this.isDesktop;
  }

  @Prop({ required: true })
  methodInfo!: StripeCardPaymentMethod | StripeBankPaymentMethod;

  get iconStyle(): string {
    return "stroke: var(--v-primary-base)";
  }

  emitBtnClick(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
    event.stopPropagation();
    if (this.isDesktop && this.showOptions) {
      this.$emit("click", event, this.methodInfo);
    }
  }

  emitCardClick(event: MouseEvent) {
    if (!this.isDesktop && this.showOptions) {
      this.$emit("click", event, this.methodInfo);
    }
  }
}
