






























































import AppDialog from "@/components/Navigation/AppDialog.vue";
import { EmitClose } from "@/mixins/Helpers";
import { requiredFieldRule, EMAIL_VALIDATION_RULES } from "@/utils/validation";
import Component from "vue-class-component";
import { Mixins, Prop } from "vue-property-decorator";
import { WatchLoading } from "@/decorators/Loading";
import { ProfileForm } from "@/mixins/Profile";
import { BaseUser } from "@sportango/backend";
import { IAlternativeContacts } from "@/types/AlternativeContacts";
import AlternativeContactList from "./AlternativeContactList.vue";
@Component({
  components: {
    AppDialog,
    AlternativeContactList
  }
})
export default class alternateContactForm extends Mixins(
  EmitClose,
  ProfileForm
) {
  @Prop({ required: true, default: false })
  isDialog!: boolean;
  showFormDialog = false;
  isEdit = false;
  currentIndex: number | null = null;

  data = {} as IAlternativeContacts;
  EmailRule = EMAIL_VALIDATION_RULES;
  requiredField = requiredFieldRule;
  isFormValid = false;
  isEditing = false;

  @WatchLoading()
  async saveAlternativeContacts() {
    if (
      this.isEditing &&
      this.userProfileInfo.alternateContacts &&
      this.currentIndex !== null
    ) {
      if (this.currentIndex === 0) {
        this.$set(this.userProfileInfo.alternateContacts, 0, this.data);
      } else {
        this.userProfileInfo.alternateContacts.splice(
          this.currentIndex,
          1,
          this.data
        );
      }
    } else {
      this.userProfileInfo.alternateContacts?.push({
        ...this.data
      });
    }
    const payload: { uid?: string; newData: Partial<BaseUser> } = {
      uid: this.user?.uid,
      newData: {
        additionalInfo: {
          ...this.userProfileInfo
        }
      }
    };
    await this.$store.dispatch("updateUser", payload);
    this.closeDialog();
  }

  editContact(item: IAlternativeContacts, index: number) {
    this.showFormDialog = true;
    this.isEditing = true;
    this.currentIndex = index;
    this.data = item;
  }
  @WatchLoading()
  async deleteContact(index: number) {
    if (index === 0) {
      this.userProfileInfo.alternateContacts?.shift();
    } else {
      this.userProfileInfo.alternateContacts =
        this.userProfileInfo.alternateContacts?.splice(index, 1);
    }
    const payload: { uid?: string; newData: Partial<BaseUser> } = {
      uid: this.user?.uid,
      newData: {
        additionalInfo: {
          ...this.userProfileInfo
        }
      }
    };
    await this.$store.dispatch("updateUser", payload);
  }
  closeDialog() {
    this.showFormDialog = false;
    this.isEdit = false;
    this.currentIndex = null;
    this.data = {
      name: "",
      email: "",
      phoneNumber: undefined
    };
  }
}
